import { TableTooltipCell } from "@/modules/Table";
import { capitalCase } from "change-case";
import React from "react";

function TooltipHeader({
  children = ``,
  header,
  maxWidth = 75,
}: {
  children?: React.ReactNode;
  header: { id: string };
  maxWidth?: number;
}) {
  const { id } = header;
  const value = capitalCase(id);
  const tooltipLabel = React.isValidElement(children)
    ? value
    : children || value;
  const tooltipText =
    typeof tooltipLabel === `string`
      ? tooltipLabel.toLowerCase()
      : value.toLowerCase();

  return (
    <TableTooltipCell
      id={`${tooltipText} header`}
      maxW={{ base: maxWidth, lg: maxWidth * 1.5 }}
      text={tooltipLabel.toString()}
    />
  );
}

export default TooltipHeader;

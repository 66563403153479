import {
  Card,
  CardHeader,
  CardBody,
  VStack,
  HStack,
  Box,
} from "@chakra-ui/react";
import { Skeleton } from "@/modules/Skeleton";

export default function UserTrustedContactPersonSkeleton() {
  return (
    <Card w="full">
      <Box w="full">
        <CardHeader>
          <Skeleton h="22px" w={280} />
        </CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={4}>
            <Skeleton h={20} w="90%" />
            <HStack>
              <Skeleton h={8} w={100} />
            </HStack>
            <HStack>
              <Skeleton h={8} w={8} />
              <Skeleton h={8} w={220} />
            </HStack>
            <HStack>
              <Skeleton h={8} w={8} />
              <Skeleton h={8} w={220} />
            </HStack>
            <HStack>
              <Skeleton h={8} w={8} />
              <Skeleton h={8} w={220} />
            </HStack>
            <HStack>
              <Skeleton h={8} w={8} />
              <Skeleton h={8} w={220} />
            </HStack>
          </VStack>
        </CardBody>
      </Box>
    </Card>
  );
}

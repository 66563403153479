import {
  InvestorDescriptor,
  UserStatus,
  UsersTableNodeFragment,
} from "@/graphql";

export type UsersTableColumnType = {
  accredited?: boolean | null | undefined;
  country?: string | null | undefined;
  email?: string | null | undefined;
  identityVerified: boolean;
  insertedAt: string;
  investorDescriptor: InvestorDescriptor;
  membershipAgreementSigned: boolean;
  name: string;
  onboardingComplete: boolean;
  status: UserStatus;
  suitable?: boolean | null | undefined;
} & Omit<
  UsersTableNodeFragment,
  | "__typename"
  | "id"
  | "institutionId"
  | "institution"
  | "identityCheckInquiryId"
  | "investorType"
  | "agreedToCustomerAgreement"
  | "roles"
  | "holdings"
>;

export type UsersTableDataType = UsersTableColumnType &
  Pick<UsersTableNodeFragment, "institution" | "holdings">;

export type UsersTableRowActionInputs = {
  deactivationReason: string;
};

export enum UsersTableRowActionModalBodyType {
  DEACTIVATE = `deactivate`,
  ACTIVATE = `activate`,
  ARCHIVE = `archive`,
  IGNORE = `ignore`,
  UNIGNORE = `unignore`,
  APPROVE = `approve`,
}

export type LowercasedUserStatusValues = Lowercase<`${UserStatus}`>;

import { UserMultiFactorAuthenticationStatus } from "@/graphql";
import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { useColors } from "@/modules/Theme";
import { useContext } from "react";
import { UserDetailsContext } from "@/features/Users";
import UserMultiFactorAuthenticationsCardActions from "./UserMultiFactorAuthenticationsCardActions";

interface MultiFactorAuthenticationMethodStatusBadgeProps {
  methodStatus: UserMultiFactorAuthenticationStatus;
}

interface UserMultiFactorAuthenticationMethodItemProps {
  methodName: string;
  methodStatus: UserMultiFactorAuthenticationStatus;
}

function EnrolledBadge() {
  const { t } = useTranslation();
  const [activeGreen] = useColors([`active`]);

  return (
    <Badge variant="active">
      <HStack gap={1}>
        <CheckCircle size={12} weight="fill" color={activeGreen} />
        <Text>{t(`enrolled`)}</Text>
      </HStack>
    </Badge>
  );
}

function NotEnrolledBadge() {
  const { t } = useTranslation();
  return (
    <Badge variant="inactive">
      <HStack gap={1}>
        <WarningCircle size={12} weight="fill" />
        <Text>{t(`not_enrolled`)}</Text>
      </HStack>
    </Badge>
  );
}

function MultiFactorAuthenticationMethodStatusBadge({
  methodStatus,
}: MultiFactorAuthenticationMethodStatusBadgeProps) {
  const showEnrolledBadge =
    methodStatus === UserMultiFactorAuthenticationStatus.Active;

  return showEnrolledBadge ? <EnrolledBadge /> : <NotEnrolledBadge />;
}

function UserMultiFactorAuthenticationMethodItem({
  methodName,
  methodStatus,
}: UserMultiFactorAuthenticationMethodItemProps) {
  return (
    <Card>
      <HStack alignItems="left" width="full">
        <Text textStyle="colfax-14-medium" textColor="grey.800">
          {methodName}
        </Text>
        <Box flexGrow={1} />
        <MultiFactorAuthenticationMethodStatusBadge
          methodStatus={methodStatus}
        />
      </HStack>
    </Card>
  );
}

export default function UserMultiFactorAuthenticationsCard() {
  const { t } = useTranslation();
  const { user } = useContext(UserDetailsContext);

  if (!user) return null;

  const { app, sms } = user.multiFactorAuthentications;
  const { isHiiveUser } = user;

  const showUserMultiFactorAuthenticationsCardActions = isHiiveUser
    ? app === UserMultiFactorAuthenticationStatus.Active
    : app === UserMultiFactorAuthenticationStatus.Active ||
      sms === UserMultiFactorAuthenticationStatus.Active;

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <HStack alignItems="left" width="full">
          <Text textStyle="colfax-22-medium">{t(`mfa`)}</Text>
          <Box flexGrow={1} />
          {showUserMultiFactorAuthenticationsCardActions && (
            <UserMultiFactorAuthenticationsCardActions />
          )}
        </HStack>
      </CardHeader>

      <CardBody>
        <VStack gap={6} alignItems="left">
          {!isHiiveUser && (
            <UserMultiFactorAuthenticationMethodItem
              methodName={t(`sms`)}
              methodStatus={sms}
            />
          )}
          <UserMultiFactorAuthenticationMethodItem
            methodName={t(`authenticator_app`)}
            methodStatus={app}
          />
        </VStack>
      </CardBody>
    </Card>
  );
}

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import {
  InvestorType,
  UserAccreditationSuitabilityQuery,
  InstitutionAccreditationSuitabilityQuery,
} from "@/graphql";
import { Status } from "@/modules/Status";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
} from "@chakra-ui/react";

interface AccreditationProps {
  accreditationLastUpdatedAt: string;
  accreditationAnswer:
    | UserAccreditationSuitabilityQuery["accreditationAnswerByUser"]
    | InstitutionAccreditationSuitabilityQuery["accreditationAnswerByInstitution"];
  isAccredited: boolean | null | undefined;
  investorType?: InvestorType | null | undefined;
}

export default function Accreditation({
  accreditationLastUpdatedAt,
  accreditationAnswer,
  isAccredited,
  investorType,
}: AccreditationProps) {
  const { t } = useTranslation();

  const qibQuestionKeys = [`us-institutional-qib`, `us-institutional-qib-v2`];

  const isQIBQuestion = accreditationAnswer?.question.key
    ? qibQuestionKeys.includes(accreditationAnswer?.question.key)
    : false;

  const answer =
    accreditationAnswer?.text || accreditationAnswer?.question?.text;

  return (
    <AccordionItem>
      <AccordionButton>
        <Text textStyle="colfax-22-medium">{t(`accreditation`)}</Text>
        <HStack>
          <Status.Accreditation
            isAccredited={isAccredited}
            investorType={investorType}
          />
          {accreditationLastUpdatedAt && (
            <Text>
              {DateTime.fromISO(accreditationLastUpdatedAt).toLocaleString(
                DateTime.DATETIME_FULL,
              )}
            </Text>
          )}
          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <AccordionPanel>
        {answer ? (
          <>
            <Text textStyle="colfax-16-medium" mb={4}>
              {t(`must_be_accredited`)}
            </Text>
            <HStack>
              <Text>{t(`answer`)}:</Text>
              <Text>{answer}</Text>
              {isQIBQuestion && <Text fontWeight="medium">{t(`yes`)}</Text>}
            </HStack>
          </>
        ) : (
          <Text>{t(`no_accreditation_answer`)}</Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

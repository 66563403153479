import { t } from "i18next";
import { Card, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useEnrollAppMfa } from "@/modules/Frontegg";
import { useState } from "react";
import SetupMfaActionCard from "./SetupMfaActionCard";
import EnrollAppMfaModal from "./EnrollAppMfaModal";
import RecoveryCodeModal from "./RecoveryCodeModal";
import EnrollMfaErrorModal from "./EnrollMfaErrorModal";

function SetupMfaPage() {
  const [enrollAppMfa, { loading }] = useEnrollAppMfa();

  const [recoveryCode, setRecoveryCode] = useState(``);
  const [qrCode, setQrCode] = useState(``);

  const {
    isOpen: isEnrollMfaOpen,
    onOpen: onOpenEnrollMfa,
    onClose: onCloseEnrollMfa,
  } = useDisclosure();
  const { isOpen: isRecoveryCodeModalOpen, onOpen: onOpenRecoveryCodeModal } =
    useDisclosure();
  const {
    isOpen: isErrorModalOpen,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const handleEnroll = async () => {
    const { data } = await enrollAppMfa();
    if (data) {
      setQrCode(data.qrCode);
      onOpenEnrollMfa();
    } else {
      onOpenErrorModal();
    }
  };

  const handleSuccessfulEnroll = (recoveryCode: string) => {
    onCloseEnrollMfa();
    setRecoveryCode(recoveryCode);
    onOpenRecoveryCodeModal();
  };

  return (
    <>
      <VStack
        spacing={6}
        marginTop={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text textStyle="heading-3xl" textColor="grey.900">
          {t(`setup_authenticator_app`)}
        </Text>
        <Card>
          <VStack spacing={6} margin={6} align="left">
            <Text>{t(`must_enroll_info`)}</Text>
            <SetupMfaActionCard
              isLoading={loading}
              handleEnroll={handleEnroll}
            />
          </VStack>
        </Card>
      </VStack>
      {isEnrollMfaOpen && (
        <EnrollAppMfaModal
          isOpen={isEnrollMfaOpen}
          handleClose={onCloseEnrollMfa}
          handleSuccess={handleSuccessfulEnroll}
          qrCode={qrCode}
        />
      )}
      {isRecoveryCodeModalOpen && (
        <RecoveryCodeModal
          recoveryCode={recoveryCode}
          isOpen={isRecoveryCodeModalOpen}
        />
      )}
      {isErrorModalOpen && (
        <EnrollMfaErrorModal
          isOpen={isErrorModalOpen}
          handleClose={onCloseErrorModal}
        />
      )}
    </>
  );
}

export default SetupMfaPage;

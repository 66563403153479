import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserDetailsContext } from "@/features/Users";
import { FeeDiscountEntityType, InvestorType } from "@/graphql";
import { DocumentCard } from "@/modules/DocumentCard";
import { FeeDiscountsCard } from "@/modules/FeeDiscountsCard";
import { VStack } from "@chakra-ui/react";

import { UserMultiFactorAuthenticationsCard } from "@/features/MultiFactorAuthentication";

import { SellerHoldingsCard } from "@/features/Holdings";
import { useBackOfficeConfiguration } from "@/modules/LaunchDarkly";
import UserDeactivationReasonCard from "./UserDeactivationReasonCard";

export default function UserDetailRightPanel() {
  const { user } = useContext(UserDetailsContext);
  const { t } = useTranslation(`users`);
  const backOfficeConfigurationEnabled = useBackOfficeConfiguration();

  if (!user) return null;

  const {
    deactivationReason,
    holdings,
    investorType,
    isActive,
    membershipAgreementUrl,
    sellerHoldings,
    status,
  } = user;
  const feeDiscountApplications = user.feeDiscountApplications || [];
  const showDeactivationCard = !isActive && !!deactivationReason;
  const showSellerHoldingsCard =
    user.investorType === InvestorType.UnaccreditedSeller;

  return (
    <VStack gap={4}>
      {showDeactivationCard && <UserDeactivationReasonCard />}
      {membershipAgreementUrl && (
        <DocumentCard
          header={t(`user_documents`)}
          textLink={t(`membership_agreement_url`)}
          url={membershipAgreementUrl}
        />
      )}

      <FeeDiscountsCard
        entity={{
          entityId: user.id,
          entityType: FeeDiscountEntityType.User,
          holdings,
          investorType,
          state: status,
        }}
        feeDiscountApplications={feeDiscountApplications}
      />

      {showSellerHoldingsCard && (
        <SellerHoldingsCard sellerHoldings={sellerHoldings} />
      )}

      {backOfficeConfigurationEnabled && <UserMultiFactorAuthenticationsCard />}
    </VStack>
  );
}

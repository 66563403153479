import { Skeleton } from "@/modules/Skeleton";
import { Card, CardBody, CardHeader, VStack } from "@chakra-ui/react";

export default function UserAccreditationSuitabilitySkeleton() {
  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader>
          <Skeleton h={6} w={180} />
        </CardHeader>
        <CardBody as={VStack} alignItems="flex-start">
          <Skeleton h={3} w={340} />
          <Skeleton h={3} w={240} />
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader>
          <Skeleton h={6} w={180} />
        </CardHeader>
        <CardBody as={VStack} alignItems="flex-start">
          <Skeleton h={3} w={340} />
          <Skeleton h={3} w={240} />
        </CardBody>
      </Card>
    </VStack>
  );
}

import { useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { HStack, PinInput, PinInputField } from "@chakra-ui/react";

export const PIN_LENGTH = 6;
const SIXTH_DIGIT_INDEX = 5;

interface CodeInputProps {
  readonly disabled?: boolean;
  readonly onChange: (value: string) => void;
  readonly onComplete: (value: string) => void;
}

function CodeInput({ disabled, onChange, onComplete }: CodeInputProps) {
  const keys = useRef(Array.from({ length: PIN_LENGTH }, () => uuidv4()));
  const [, setPinValue] = useState<string>(``);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleOnChange = useMemo(
    () => (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const handleOnComplete = useMemo(
    () => (value: string) => {
      setPinValue((prevValue: string) => {
        if (value[SIXTH_DIGIT_INDEX] !== prevValue[SIXTH_DIGIT_INDEX]) {
          onComplete(value);
        }

        const newValue = value;
        return newValue;
      });
    },
    [onComplete],
  );

  const handlePinInputFocus = (value: string, index: number) => {
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <HStack gap={3}>
      <PinInput
        autoFocus
        isDisabled={disabled}
        placeholder=""
        size="lg"
        type="number"
        onChange={handleOnChange}
        onComplete={handleOnComplete}
        variant="outline"
      >
        {keys.current.map((key, index) => (
          <PinInputField
            key={key}
            ref={(element) => {
              // eslint-disable-next-line functional/immutable-data
              inputRefs.current[index] = element;
            }}
            onChange={(event) => handlePinInputFocus(event.target.value, index)}
          />
        ))}
      </PinInput>
    </HStack>
  );
}

export default CodeInput;

import { DeviceMobileCamera } from "@phosphor-icons/react";

import {
  Box,
  Button,
  Card,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface SetupMfaActionCardProps {
  handleEnroll: () => void;
  isLoading: boolean;
}

function SetupMfaActionCard({
  handleEnroll,
  isLoading,
}: SetupMfaActionCardProps) {
  const { t } = useTranslation();

  return (
    <Card w="full" p={4} variant="flat" backgroundColor="white">
      <Stack
        alignItems="center"
        direction={{ base: `column`, lg: `row` }}
        gap={4}
      >
        <Box
          alignItems="center"
          backgroundColor="grey.50"
          borderRadius="100%"
          display="flex"
          height="2.5rem"
          justifyContent="center"
          width="2.5rem"
          flexShrink={0}
        >
          <DeviceMobileCamera size={24} />
        </Box>

        <VStack alignItems={{ base: `center`, lg: `flex-start` }}>
          <HStack>
            <Text textStyle="heading-md">{t(`authenticator_app`)}</Text>
          </HStack>
          <Text
            textStyle="text-sm"
            textAlign={{ base: `center`, lg: `initial` }}
          >
            {t(`security_mfa_authenticator_app_action_card_sub_text`)}
          </Text>
        </VStack>

        <Spacer display={{ base: `none`, lg: `block` }} />
        <Button
          onClick={handleEnroll}
          size="lg"
          variant="hiive"
          px={12}
          isLoading={isLoading}
        >
          {t(`enroll`)}
        </Button>
      </Stack>
    </Card>
  );
}

export default SetupMfaActionCard;

import { createContext, ReactNode, useMemo } from "react";
import { CombinedError } from "urql";

import { mapUserDetails } from "@/features/Users";
import { useUserPageQuery } from "@/graphql";

type UserDetailsContext = {
  error?: CombinedError;
  fetching: boolean;
  user: ReturnType<typeof mapUserDetails>;
};

const DEFAULT_CONTEXT: UserDetailsContext = {
  error: undefined,
  fetching: false,
  user: null,
};

const Context = createContext<UserDetailsContext>(DEFAULT_CONTEXT);
const { Provider } = Context;

interface UserDetailsProviderProps {
  userId: string;
  children: ReactNode;
}

function UserDetailsProvider({ userId, children }: UserDetailsProviderProps) {
  const [{ fetching, error, data }] = useUserPageQuery({
    variables: { userId },
    requestPolicy: `cache-and-network`,
  });

  const user = useMemo(() => mapUserDetails(data), [data]);

  return <Provider value={{ fetching, error, user }}>{children}</Provider>;
}

export { Context, UserDetailsProvider };

import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import {
  useMultiEntityExperienceEnabled,
  useTrustedContactPersonEnabled,
} from "@/modules/LaunchDarkly";
import {
  InvestorStatus,
  InvestorType,
  useUserPageNavMenuUserQuery,
} from "@/graphql";
import { Link } from "@/modules/Navigation";
import { Button, HStack } from "@chakra-ui/react";
import UserPageNavMenuSkeleton from "./UserPageNavMenuSkeleton";

interface UserPageNavMenuProps {
  userId: string;
}

export default function UserPageNavMenu({ userId }: UserPageNavMenuProps) {
  const [{ data, fetching }] = useUserPageNavMenuUserQuery({
    variables: { userId },
  });

  const { t } = useTranslation();
  const router = useRouter();
  const { asPath } = router;

  const multiEntityExperienceEnabled = useMultiEntityExperienceEnabled();
  const isTrustedContactPersonEnabled = useTrustedContactPersonEnabled();

  if (fetching) return <UserPageNavMenuSkeleton />;

  const { investorType, investorStatus } = data?.user ?? {};

  const shouldRenderTCPRoute =
    (investorType === InvestorType.Trader ||
      investorType === InvestorType.UnaccreditedSeller) &&
    investorStatus === InvestorStatus.Individual;

  return (
    <HStack p={4} ml={4} gap={4} bg="white" borderRadius={8} w="fit-content">
      <Link href={`/users/${userId}`} replace>
        <Button
          variant="ghost"
          size="lg"
          isActive={asPath === `/users/${userId}`}
        >
          {t(`user_overview`)}
        </Button>
      </Link>
      {multiEntityExperienceEnabled && (
        <Link href={`/users/${userId}/entities`} replace>
          <Button
            variant="ghost"
            size="lg"
            isActive={asPath === `/users/${userId}/entities`}
          >
            {`${t(`entities`)}`}
          </Button>
        </Link>
      )}
      {investorType !== InvestorType.UnaccreditedSeller && (
        <Link href={`/users/${userId}/watchlist`} replace>
          <Button
            variant="ghost"
            size="lg"
            isActive={asPath === `/users/${userId}/watchlist`}
          >
            {t(`watchlist`)}
          </Button>
        </Link>
      )}
      <Link href={`/users/${userId}/accreditation-suitability`} replace>
        <Button
          variant="ghost"
          size="lg"
          isActive={asPath === `/users/${userId}/accreditation-suitability`}
        >
          {`${t(`accreditation`)}/${t(`suitability`)}`}
        </Button>
      </Link>
      {isTrustedContactPersonEnabled && shouldRenderTCPRoute && (
        <Link href={`/users/${userId}/trusted-contact-person`} replace>
          <Button
            variant="ghost"
            size="lg"
            isActive={asPath === `/users/${userId}/trusted-contact-person`}
          >
            {t(`trusted_contact_person`)}
          </Button>
        </Link>
      )}
    </HStack>
  );
}

import { UsersTableQuery, UsersTableQueryVariables } from "@/graphql";
import merge from "lodash/merge";
import { UseQueryState } from "urql";
import { UsersTableDataType } from "@/features/Users";

const DEFAULT = { users: [], totalCount: 0, pageInfo: undefined };

export const transformUsersQueryToData = (
  responseData:
    | UseQueryState<UsersTableQuery, UsersTableQueryVariables>
    | undefined,
) => {
  if (!responseData?.data?.users) {
    return {
      ...DEFAULT,
      error: responseData?.error,
      fetching: responseData?.fetching,
    };
  }

  const { edges } = responseData.data.users;
  const users: UsersTableDataType[] = edges.map((edge) => {
    const { country } = edge.node;

    return merge(edge.node, {
      country: country ? country.name : ``,
    });
  });

  return {
    users,
    totalCount: responseData.data.users.totalCount,
    pageInfo: responseData.data.users.pageInfo,
    error: responseData.error,
    loading: responseData.fetching,
  };
};

import {
  ListingSort,
  ListingSortField,
  ListingsTableNodeFragment,
  ListingState,
  SortDirection,
  useListingsTableQuery,
} from "@/graphql";
import { SimpleTable, SimpleTableQueryVariables } from "@/modules/SimpleTable";
import { TableSortState } from "@/modules/Table";
import { useTranslation } from "react-i18next";

import { ListingsTableRowActions, mapToTable } from "@/features/Listings";

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Text } from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import { useRouter } from "next/router";
import columns from "./UserActiveListingTable/Columns";
import { UserActiveListingsTableDataType } from "./UserActiveListingTable/types";

const TABLE_PAGE = 1;
const TABLE_PER_PAGE = 5;

function UserActiveListingsTable({ userId }: { readonly userId: string }) {
  const defaultVariables = {
    page: TABLE_PAGE,
    first: 20,
    filterBy: {
      states: [ListingState.Open, ListingState.InReview],
      sellerId: userId,
    },
    sortBy: {
      field: ListingSortField.InsertedAt,
      direction: SortDirection.Desc,
    },
  };
  const { t } = useTranslation();
  const { push } = useRouter();
  const [sort, setSort] = useState<TableSortState>(defaultVariables.sortBy);
  const [variables, setVariables] =
    useState<SimpleTableQueryVariables>(defaultVariables);

  const [data] = useListingsTableQuery({
    variables: {
      ...variables,
      filterBy: {
        ...variables.filterBy,
        states: variables?.filterBy?.states as ListingState[],
      },
      sortBy: sort as ListingSort,
    },
    requestPolicy: `cache-and-network`,
  });

  const tableData = useMemo(() => mapToTable(data), [data]);

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original;
      push(`/listings/${id}`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (row: Row<Record<string, unknown>>) => (
      <ListingsTableRowActions
        listingId={row.original.id as string}
        solicited={row.original.solicited as boolean}
        companyState={
          (row.original as ListingsTableNodeFragment).company?.status
        }
        state={row.original.state as string}
      />
    ),
    [],
  );

  if (!data) return null;

  if (tableData.listings.length === 0) {
    return <Text>{t`user_active_listings_table_empty_state`}</Text>;
  }

  return (
    <SimpleTable<SimpleTableQueryVariables, UserActiveListingsTableDataType[]>
      tableStyleProps={{ border: `solid 1px #D5D5D5`, borderTop: `unset` }}
      page={variables.page as number}
      perPage={TABLE_PER_PAGE}
      sort={sort}
      setSort={setSort as Dispatch<SetStateAction<TableSortState | undefined>>}
      columns={columns}
      setVariables={setVariables}
      tableData={tableData.listings}
      pageInfo={tableData.pageInfo}
      totalCount={tableData.totalCount}
      renderRowActions={renderRowActions}
      error={data.error}
      loading={data.fetching}
      onRowClick={onRowClick}
    />
  );
}

export default UserActiveListingsTable;

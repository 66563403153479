import { useState } from "react";
import {
  SellerListedHoldingFragment,
  SellerUnlistedHoldingFragment,
} from "@/graphql";
import { formatShares } from "@/modules/NumeralFormat";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ConfirmConvertHoldingModal from "./UnlistedHoldingConversionModal";

interface SellerHoldingsCardProps {
  sellerHoldings: SellerHolding[];
}

interface SellerHoldingCardItemProps {
  holding: SellerHolding;
  onButtonClick: () => void;
}

export type SellerHolding =
  | SellerListedHoldingFragment
  | SellerUnlistedHoldingFragment;

function SellerListedHoldingItem({
  holding,
}: {
  holding: SellerListedHoldingFragment;
}) {
  const { t } = useTranslation();

  return (
    <Card variant="holding-card" data-testid={holding.company?.name}>
      <VStack key={holding.id}>
        <HStack alignItems="left" width="full">
          <Text textStyle="colfax-14-medium" textColor="grey.800">
            {t(`listed_holding`)}
          </Text>
          <Box flexGrow={1} />
          <Text textStyle="colfax-14-small" textColor="grey.900">
            {holding.company?.name}
          </Text>
        </HStack>
        <HStack alignItems="left" width="full">
          <Text textStyle="colfax-14-medium" textColor="grey.800">
            {t(`number_of_shares_lower`)}
          </Text>
          <Box flexGrow={1} />
          <Text textStyle="colfax-14-small" textColor="grey.900">
            {holding.numShares ? formatShares(holding.numShares) : `-`}
          </Text>
        </HStack>
      </VStack>
    </Card>
  );
}

function SellerUnlistedHoldingItem({
  holding,
  onButtonClick,
}: {
  holding: SellerUnlistedHoldingFragment;
  onButtonClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Card variant="holding-card">
      <VStack key={holding.id}>
        <HStack alignItems="left" width="full">
          <Text textStyle="colfax-14-medium" textColor="grey.800">
            {t(`unlisted_holding`)}
          </Text>
          <Box flexGrow={1} />
          <Text textStyle="colfax-14-small" textColor="grey.900">
            {holding.companyName}
          </Text>
        </HStack>
        <HStack alignItems="left" width="full">
          <Text textStyle="colfax-14-medium" textColor="grey.800">
            {t(`number_of_shares_lower`)}
          </Text>
          <Box flexGrow={1} />
          <Text textStyle="colfax-14-small" textColor="grey.900">
            {holding.numShares ? formatShares(holding.numShares) : `-`}
          </Text>
        </HStack>
        <Button variant="solid" width="full" onClick={onButtonClick}>
          {t(`convert_holding`)}
        </Button>
      </VStack>
    </Card>
  );
}

function SellerHoldingItem({
  holding,
  onButtonClick,
}: SellerHoldingCardItemProps) {
  if (holding.__typename === `Holding`)
    return <SellerListedHoldingItem holding={holding} />;
  if (holding.__typename === `UnlistedHolding`)
    return (
      <SellerUnlistedHoldingItem
        holding={holding}
        onButtonClick={onButtonClick}
      />
    );

  return null;
}

export default function SellerHoldingsCard({
  sellerHoldings,
}: SellerHoldingsCardProps) {
  const { t } = useTranslation();

  const showSellerHoldings = sellerHoldings.length ?? 0;
  const [pendingConversionHolding, setPendingConversionHolding] = useState<
    SellerHolding | undefined
  >();

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{t(`holdings`)}</Text>
      </CardHeader>
      <CardBody>
        {showSellerHoldings ? (
          sellerHoldings?.map((holding) => (
            <SellerHoldingItem
              key={holding.id}
              holding={holding}
              onButtonClick={() => setPendingConversionHolding(holding)}
            />
          ))
        ) : (
          <Text textStyle="colfax-14-small" textColor="grey.900">
            {t(`no_holdings`)}
          </Text>
        )}
      </CardBody>
      <ConfirmConvertHoldingModal
        open={!!pendingConversionHolding}
        onClose={() => setPendingConversionHolding(undefined)}
        holding={pendingConversionHolding}
        currentHoldings={sellerHoldings.filter(
          (holding): holding is SellerListedHoldingFragment =>
            holding.__typename === `Holding`,
        )}
      />
    </Card>
  );
}

import { VStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { UsersTableProvider } from "@/features/Users";
import UsersTable, { UsersUrlParams } from "./UsersTable";

export default function UsersPage({ query }: { query: UsersUrlParams }) {
  const { t } = useTranslation();

  return (
    <VStack p={8} gap={0} alignItems="flex-start">
      <Text mb={4} textStyle="colfax-22-medium">
        {t(`users`)}
      </Text>
      <UsersTableProvider query={query}>
        <UsersTable />
      </UsersTableProvider>
    </VStack>
  );
}

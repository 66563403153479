import {
  useUserAccreditationSuitabilityQuery,
  useUserSuitabilityV2Query,
} from "@/graphql";
import { Accordion } from "@chakra-ui/react";
import { Accreditation } from "@/features/Accreditation";
import { Suitability, SuitabilityV2 } from "@/features/Suitability";
import { useSuitabilityV3Enabled } from "@/modules/LaunchDarkly";
import UserAccreditationSuitabilitySkeleton from "./UserAccreditationSuitabilitySkeleton";

interface UserAccreditationSuitabilityProps {
  userId: string;
}

export default function UserAccreditationSuitability({
  userId,
}: UserAccreditationSuitabilityProps) {
  const [{ data, fetching }] = useUserAccreditationSuitabilityQuery({
    variables: { userId },
  });

  const isSuitabilityV3Enabled = useSuitabilityV3Enabled();

  const [{ data: suitabilityV2Data, fetching: suitabilityV2Fetching }] =
    useUserSuitabilityV2Query({ variables: { userId } });

  if (fetching || suitabilityV2Fetching) {
    return <UserAccreditationSuitabilitySkeleton />;
  }

  if (!data || !data.user) {
    return null;
  }

  const { institution, investorType, institutionId, accredited, suitable } =
    data.user;

  const accreditationLastUpdatedAt = institutionId
    ? data?.user?.institution?.accreditationLastUpdatedAt
    : data?.user?.accreditationLastUpdatedAt;
  const suitabilityLastUpdatedAt = institutionId
    ? data?.user?.institution?.suitabilityLastUpdatedAt
    : data?.user?.suitabilityLastUpdatedAt;

  const isAccredited = institutionId ? institution?.accredited : accredited;
  const isSuitable = institutionId ? institution?.suitable : suitable;

  return (
    <Accordion w="full" allowMultiple defaultIndex={[0, 1]}>
      <Accreditation
        accreditationLastUpdatedAt={accreditationLastUpdatedAt}
        accreditationAnswer={data.accreditationAnswerByUser}
        isAccredited={isAccredited}
        investorType={investorType}
      />
      {isSuitabilityV3Enabled ? (
        <SuitabilityV2
          suitabilityLastUpdatedAt={suitabilityLastUpdatedAt}
          suitabilityAnswers={suitabilityV2Data?.currentSuitabilityAnswers}
          isSuitable={isSuitable}
          investorType={investorType}
        />
      ) : (
        <Suitability
          suitabilityLastUpdatedAt={suitabilityLastUpdatedAt}
          investmentGoalAnswers={data.investmentGoalAnswersByUser}
          isSuitable={isSuitable}
          investorType={investorType}
        />
      )}
    </Accordion>
  );
}

import { RESET_PAGINATION_PARAMS } from "@/constants";
import {
  InvestorType,
  UserMultiFactorAuthenticationStatus,
  UsersTableEdgeFragment,
  UsersTableNodeFragment,
  UserStatus,
} from "@/graphql";
import { Input } from "@/modules/Form";
import {
  SimpleTable,
  SimpleTableQueryVariables,
  SimpleTableUrlParams,
  TableContext,
} from "@/modules/SimpleTable";
import { Status } from "@/modules/Status";
import {
  DEFAULT_TABLE_TOOLTIP_MAX_WIDTH,
  IndicatorColor,
  Table,
  TableColumns,
  TableTooltipCell,
} from "@/modules/Table";

import { Box, HStack, Tab } from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";

import { capitalCase } from "change-case";
import { useRouter } from "next/router";
import { useCallback, useContext, useMemo } from "react";
import { UseFormRegister } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  getMembershipAgreementSigned,
  transformUsersQueryToData,
  UsersTableColumnType,
  UsersTableContext,
  UsersTableDataType,
} from "@/features/Users";
import { DateTimeFormat, formatDate } from "@/modules/NumeralFormat";
import { TooltipHeader } from "@/modules/TooltipHeader";
import { useBackOfficeConfiguration } from "@/modules/LaunchDarkly";
import UsersTableRowActions from "./UsersTableRowActions";

export type UsersUrlParams = SimpleTableUrlParams & {
  status?: string;
  page: string;
  before: string;
  after: string;
  first: string;
  last: string;
};

const TOOLTIP_MAX_WIDTH = { ...DEFAULT_TABLE_TOOLTIP_MAX_WIDTH, xl: 125 };

const getColumns = (
  backOfficeConfigurationEnabled: boolean,
): TableColumns<UsersTableColumnType, UsersTableDataType> => ({
  name: {
    header: () => <Trans i18nKey="name" />,
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell
        id={id}
        text={cell.getValue()}
        maxW={TOOLTIP_MAX_WIDTH}
      />
    ),
  },
  country: {
    header: (props) => <TooltipHeader {...props} maxWidth={45} />,
    cell: ({ cell }) => {
      const { country, institution } = cell.getContext().row.original;
      const value = institution?.country?.name || country;
      return <TableTooltipCell id={value} text={value} />;
    },
  },
  email: {
    header: () => <Trans i18nKey="email" />,
    cell: ({ cell, column: { id } }) => (
      <TableTooltipCell id={id} text={cell.getValue()} />
    ),
  },
  investorDescriptor: {
    header: (props) => <TooltipHeader {...props} />,
    cell: ({ cell }) => {
      const { investorDescriptor } = cell.getContext().row.original;
      const value = capitalCase(investorDescriptor);
      return (
        <TableTooltipCell
          id={value.toLowerCase()}
          text={value}
          maxW={TOOLTIP_MAX_WIDTH}
        />
      );
    },
    enableSorting: false,
  },
  onboardingComplete: {
    header: (props) => <TooltipHeader {...props} maxWidth={45} />,
    cell: ({ cell }) => {
      const onboardingComplete = cell.getValue();
      return (
        <Status.Generic
          booleanFlag={onboardingComplete}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipLabel={
            <Trans i18nKey={onboardingComplete ? `complete` : `incomplete`} />
          }
        />
      );
    },
    enableSorting: false,
  },
  accredited: {
    header: (props) => <TooltipHeader {...props} maxWidth={45} />,
    cell: ({ cell }) => {
      const { institutionId, institution, investorType } = cell.getContext().row
        .original as UsersTableNodeFragment;

      const isAccredited = (
        institutionId ? institution?.accredited : cell.getValue()
      ) as boolean | null | undefined;

      const tooltip = match({ isAccredited, investorType })
        .with(
          { investorType: InvestorType.UnaccreditedSeller },
          () => `not_applicable`,
        )
        .with({ investorType: InvestorType.Broker }, () => `not_applicable`)
        .with({ isAccredited: true }, () => `accredited`)
        .with({ isAccredited: false }, () => `incomplete_or_unaccredited`)
        .otherwise(() => `not_applicable`);

      return (
        <Status.Accreditation
          isAccredited={isAccredited}
          investorType={investorType}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipLabel={<Trans i18nKey={tooltip} />}
        />
      );
    },
    enableSorting: false,
  },
  membershipAgreementSigned: {
    header: () => <Trans i18nKey="ca" />,
    cell: ({ cell }) => {
      const {
        institutionId,
        institution,
        investorType,
        agreedToCustomerAgreement,
        membershipAgreementSigned,
      } = cell.getContext().row.original as UsersTableNodeFragment;

      const isMembershipAgreementSigned = getMembershipAgreementSigned(
        agreedToCustomerAgreement,
        institutionId,
        institution?.membershipAgreementSigned,
        membershipAgreementSigned,
      );

      return (
        <Status.MembershipAgreement
          membershipAgreementSigned={isMembershipAgreementSigned}
          investorType={investorType}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipLabel={
            <Trans
              i18nKey={isMembershipAgreementSigned ? `complete` : `incomplete`}
            />
          }
        />
      );
    },
    enableSorting: false,
  },
  identityVerified: {
    header: () => <Trans i18nKey="id" />,
    cell: ({ cell }) => {
      const { investorType, institutionId } = cell.getContext().row
        .original as UsersTableNodeFragment;
      const identityVerified = cell.getValue() as boolean | null | undefined;
      const isInstitutionUser = !!institutionId;

      const tooltip = match({
        identityVerified,
        investorType,
        isInstitutionUser,
      })
        .with({ isInstitutionUser: true }, () => `not_applicable`)
        .with({ investorType: InvestorType.Broker }, () => `not_applicable`)
        .with({ identityVerified: true }, () => `verified`)
        .with({ identityVerified: false }, () => `incomplete`)
        .otherwise(() => `incomplete`);

      return (
        <Status.IdentityVerification
          identityVerified={identityVerified}
          investorType={investorType}
          isInstitutionUser={isInstitutionUser}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipLabel={<Trans i18nKey={tooltip} />}
        />
      );
    },
    enableSorting: false,
  },
  suitable: {
    header: (props) => (
      <TooltipHeader {...props} maxWidth={45}>
        <Trans i18nKey="suitability" />
      </TooltipHeader>
    ),
    cell: ({ cell }) => {
      const { institutionId, institution, investorType } = cell.getContext().row
        .original as UsersTableNodeFragment;

      const isSuitable = (
        institutionId ? institution?.suitable : cell.getValue()
      ) as boolean | null | undefined;

      const tooltip = match({ isSuitable, investorType })
        .with(
          { investorType: InvestorType.UnaccreditedSeller },
          () => `not_applicable`,
        )
        .with({ investorType: InvestorType.Broker }, () => `not_applicable`)
        .with({ isSuitable: true }, () => `complete`)
        .with({ isSuitable: false }, () => `incomplete`)
        .otherwise(() => `incomplete`);

      return (
        <Status.Suitability
          isSuitable={isSuitable}
          investorType={investorType}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipLabel={<Trans i18nKey={tooltip} />}
        />
      );
    },
    enableSorting: false,
  },
  multiFactorAuthentications: {
    header: () => <Trans i18nKey="mfa" />,
    cell: ({ cell }) => {
      const { app, sms } = cell.getValue();

      const mfaActive =
        app === UserMultiFactorAuthenticationStatus.Active ||
        sms === UserMultiFactorAuthenticationStatus.Active;

      const tooltip = match<{
        app: UserMultiFactorAuthenticationStatus;
        sms: UserMultiFactorAuthenticationStatus;
      }>({ app, sms })
        .with(
          {
            app: UserMultiFactorAuthenticationStatus.Active,
            sms: UserMultiFactorAuthenticationStatus.Active,
          },
          () => `both_mfa_methods`,
        )
        .with({ app: UserMultiFactorAuthenticationStatus.Active }, () => `app`)
        .with({ sms: UserMultiFactorAuthenticationStatus.Active }, () => `sms`)
        .otherwise(() => `not_enrolled`);

      return mfaActive ? (
        <Status.Generic
          booleanFlag
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipLabel={<Trans i18nKey={tooltip} />}
        />
      ) : (
        <Status.Empty tooltipLabel={<Trans i18nKey="not_enrolled" />} />
      );
    },
    enableSorting: false,
    isVisible: backOfficeConfigurationEnabled,
  },
  status: {
    header: () => <Trans i18nKey="status" />,
    cell: ({ cell }) => {
      const { status } = cell.getContext().row.original;
      const indicatorColor = match(status)
        .with(UserStatus.Approved, () => IndicatorColor.ACTIVE)
        .with(UserStatus.AwaitingApproval, () => IndicatorColor.PENDING)
        .with(UserStatus.Deactivated, () => IndicatorColor.ARCHIVED)
        .with(UserStatus.Incomplete, () => IndicatorColor.PENDING)
        .otherwise(() => IndicatorColor.DISABLED);

      return (
        <Status.Indicator
          text={capitalCase(status)}
          indicatorProps={{ bg: indicatorColor }}
        />
      );
    },
    enableSorting: false,
  },
  insertedAt: {
    header: () => <Trans i18nKey="inserted_at" />,
    cell: ({ cell }) => {
      const { insertedAt } = cell.getContext().row.original;
      return formatDate(insertedAt, DateTimeFormat.fullDateWithMonthShort);
    },
  },
});

function UsersTableTabs({
  variables,
  setVariables,
}: {
  variables: SimpleTableQueryVariables;
  setVariables: (variables: SimpleTableQueryVariables) => void;
}) {
  const { t } = useTranslation();

  const index = useMemo(
    () =>
      match(variables.filterBy?.status)
        .with(UserStatus.Approved, () => 1)
        .with(UserStatus.AwaitingApproval, () => 2)
        .with(UserStatus.Incomplete, () => 3)
        .with(UserStatus.Deactivated, () => 4)
        .with(UserStatus.Ignored, () => 5)
        .otherwise(() => 0),
    [variables],
  );

  const handleTabChange = useCallback(
    (index: number) => {
      setVariables({
        ...variables,
        ...RESET_PAGINATION_PARAMS,
        filterBy: {
          ...variables.filterBy,
          status: match(index)
            .with(1, () => UserStatus.Approved)
            .with(2, () => UserStatus.AwaitingApproval)
            .with(3, () => UserStatus.Incomplete)
            .with(4, () => UserStatus.Deactivated)
            .with(5, () => UserStatus.Ignored)
            .otherwise(() => null),
        },
      });
    },
    [setVariables, variables],
  );

  return (
    <Table.Tabs index={index} onChange={handleTabChange}>
      <Tab>{t(`all_users`)}</Tab>
      <Tab>{t(`approved`)}</Tab>
      <Tab>{t(`awaiting_approval`)}</Tab>
      <Tab>{t(`incomplete`)}</Tab>
      <Tab>{t(`deactivated`)}</Tab>
      <Tab>{t(`ignored`)}</Tab>
    </Table.Tabs>
  );
}

function UsersTableSearch({
  register,
}: {
  register: UseFormRegister<{ searchText: string }>;
}) {
  const { t } = useTranslation();

  return (
    <HStack alignItems="center">
      <Input.Search
        placeholder={t(`search_email_name`)}
        w={430}
        {...register(`searchText`)}
      />
    </HStack>
  );
}

export default function UsersTable() {
  const { push } = useRouter();
  const { page, searchText, register, sort, variables, setSort, setVariables } =
    useContext(TableContext);
  const { data } = useContext(UsersTableContext);

  const backOfficeConfigurationEnabled = useBackOfficeConfiguration();

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original as UsersTableNodeFragment;
      push(`/users/${id}`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (row: Row<Record<string, unknown>>) => (
      <UsersTableRowActions
        user={row.original as UsersTableEdgeFragment["node"]}
      />
    ),
    [],
  );

  const tableData = useMemo(() => transformUsersQueryToData(data), [data]);

  if (!data) return null;

  return (
    <>
      <UsersTableTabs variables={variables} setVariables={setVariables} />
      <Box w="full" bg="white" p={4} borderTopRightRadius={8}>
        <UsersTableSearch register={register} />
      </Box>
      <SimpleTable<
        SimpleTableQueryVariables,
        UsersTableColumnType[],
        UsersTableDataType[]
      >
        page={page}
        sort={sort}
        setSort={setSort}
        searchText={searchText}
        setVariables={setVariables}
        columns={getColumns(backOfficeConfigurationEnabled)}
        tableData={tableData.users}
        pageInfo={tableData.pageInfo}
        totalCount={tableData.totalCount}
        renderRowActions={renderRowActions}
        onRowClick={onRowClick}
        error={data.error}
        loading={data.fetching}
      />
    </>
  );
}

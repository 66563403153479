import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  UserDetailsContext,
  UserDetailsProvider,
  UserPageNavMenu,
} from "@/features/Users";
import {
  EntityCard,
  EntityCardSkeleton,
  NoEntitiesCard,
} from "@/features/Entities";

import { ErrorWrapper } from "@/modules/Error";
import { DetailPageLayout } from "@/modules/Layout";
import { UserDetailFragment } from "@/graphql";
import { VStack } from "@chakra-ui/react";
import { DocumentCard } from "@/modules/DocumentCard";

interface UserEntitiesProps {
  userId: string;
}

type UserPageMainPanelProps = { user: UserDetailFragment; fetching: boolean };

function UserEntitiesMainPanel({ user, fetching }: UserPageMainPanelProps) {
  const hasEntities = !!user.entities && user.entities.length > 0;

  if (fetching) {
    return <EntityCardSkeleton />;
  }

  return (
    <VStack>
      {hasEntities ? (
        user.entities?.map((entity) => (
          <EntityCard entity={entity} key={entity?.id} />
        ))
      ) : (
        <NoEntitiesCard />
      )}
    </VStack>
  );
}

function UserEntitiesPageContent() {
  const { error, fetching, user } = useContext(UserDetailsContext);
  const { t } = useTranslation(`users`);

  return (
    <ErrorWrapper error={error?.message}>
      <DetailPageLayout
        isTwoColumn
        mainPanel={
          user && <UserEntitiesMainPanel user={user} fetching={fetching} />
        }
        tabMenu={user && <UserPageNavMenu userId={user.id} />}
        rightPanel={
          user &&
          user.membershipAgreementUrl && (
            <DocumentCard
              header={t(`user_documents`)}
              textLink={t(`membership_agreement_url`)}
              url={user.membershipAgreementUrl}
            />
          )
        }
      />
    </ErrorWrapper>
  );
}

export default function UserEntities({ userId }: UserEntitiesProps) {
  return (
    <UserDetailsProvider userId={userId}>
      <UserEntitiesPageContent />
    </UserDetailsProvider>
  );
}

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { InvestorType, UserSuitabilityV2Query } from "@/graphql";
import { Markdown } from "@/modules/Markdown";
import { Status } from "@/modules/Status";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import styles from "./suitability-markdown-style.module.css";

interface SuitabilityAnswersProps {
  answers: UserSuitabilityV2Query["currentSuitabilityAnswers"];
}

function SuitabilityAnswersV2({ answers }: SuitabilityAnswersProps) {
  const { t } = useTranslation();

  if (!answers || answers.length === 0) {
    return <Text>{t(`no_suitability_answers`)}</Text>;
  }

  return (
    <VStack alignItems="flex-start" gap={12}>
      {answers
        .slice()
        .sort(
          (a, b) =>
            a.suitabilityOption.suitabilityQuestion.order -
            b.suitabilityOption.suitabilityQuestion.order,
        )
        .map((answer) => (
          <VStack key={answer.id} alignItems="flex-start">
            <div className={styles[`suitability-question-text-markdown`]}>
              <Markdown
                markdown={answer.suitabilityOption.suitabilityQuestion.text}
              />
            </div>
            {answer.suitabilityOption.suitabilityQuestion.description && (
              <div className={styles[`suitability-markdown`]}>
                <Markdown
                  markdown={
                    answer.suitabilityOption.suitabilityQuestion.description
                  }
                />
              </div>
            )}
            <HStack>
              <Text>{t(`answer`)}:</Text>
              <Text fontWeight="medium">
                {answer.text || answer.suitabilityOption.text}
              </Text>
            </HStack>
          </VStack>
        ))}
    </VStack>
  );
}

interface SuitabilityProps {
  suitabilityLastUpdatedAt: string;
  suitabilityAnswers?: UserSuitabilityV2Query["currentSuitabilityAnswers"];
  isSuitable: boolean | null | undefined;
  investorType?: InvestorType | null | undefined;
}

export default function SuitabilityV2({
  suitabilityLastUpdatedAt,
  suitabilityAnswers,
  isSuitable,
  investorType,
}: SuitabilityProps) {
  const { t } = useTranslation();

  return (
    <AccordionItem>
      <AccordionButton>
        <Text textStyle="colfax-22-medium">{t(`suitability`)}</Text>
        <HStack>
          <Status.Suitability
            isSuitable={isSuitable}
            investorType={investorType}
          />
          {suitabilityLastUpdatedAt && (
            <Text>
              {DateTime.fromISO(suitabilityLastUpdatedAt).toLocaleString(
                DateTime.DATETIME_FULL,
              )}
            </Text>
          )}
          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <AccordionPanel>
        {suitabilityAnswers && (
          <SuitabilityAnswersV2 answers={suitabilityAnswers} />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

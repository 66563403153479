import {
  SigningProcedure,
  SortDirection,
  TransactionFilter,
  TransactionSort,
  TransactionSortField,
  TransactionsTableNodeFragment,
  TransactionState,
  UserActiveTransactionsTableUserFragment,
  useTransactionsTableQuery,
} from "@/graphql";
import { SimpleTable, SimpleTableQueryVariables } from "@/modules/SimpleTable";
import { TableColumns, TableSortState } from "@/modules/Table";
import { Trans, useTranslation } from "react-i18next";
import {
  CompanyTableCell,
  DatabaseIDTableCell,
  SharesPPSTableCell,
  SigningProcedureTableCell,
  UpdatedAtTableCell,
  TransactionsTableRowActions,
  TransactionStateTableCell,
  transformTransactionsQueryToData,
} from "@/features/Transactions";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Row } from "@tanstack/react-table";
import { useRouter } from "next/router";
import { Text } from "@chakra-ui/react";

type UserActiveTransactionsTableColumnType = {
  companyName: string;
  updatedAt?: string | null;
  id: string;
  signingProcedure: SigningProcedure;
  sharesPPS: string;
  state: string;
};

type UserActiveTransactionsTableDataType =
  UserActiveTransactionsTableColumnType &
    Pick<
      TransactionsTableNodeFragment,
      | "document"
      | "bid"
      | "stateChangedAt"
      | "numShares"
      | "pricePerShare"
      | "inPipeline"
    >;

const TABLE_PAGE = 1;
const TABLE_PER_PAGE = 5;

function UserActiveTransactionsTable({
  user,
}: {
  user: UserActiveTransactionsTableUserFragment;
}) {
  const defaultVariables = {
    page: TABLE_PAGE,
    first: 20,
    filterBy: {
      userId: user.id,
      states: [
        TransactionState.AwaitingClosing,
        TransactionState.BidAccepted,
        TransactionState.ClosedFeePending,
        TransactionState.InReview,
        TransactionState.IssuerApproved,
        TransactionState.IssuerPendingApproval,
        TransactionState.Pending,
      ],
    },
    sortBy: {
      field: TransactionSortField.UpdatedAt,
      direction: SortDirection.Desc,
    },
  };
  const { t } = useTranslation();
  const [sort, setSort] = useState<TableSortState>(defaultVariables.sortBy);
  const { push } = useRouter();
  const [variables, setVariables] =
    useState<SimpleTableQueryVariables>(defaultVariables);

  const [data] = useTransactionsTableQuery({
    variables: {
      ...variables,
      filterBy: {
        ...variables.filterBy,
        securitySpecialistUserIds: [],
      } as TransactionFilter,
      sortBy: sort as TransactionSort,
    },
    requestPolicy: `cache-and-network`,
  });

  const columns: TableColumns<
    UserActiveTransactionsTableColumnType,
    UserActiveTransactionsTableDataType
  > = {
    companyName: {
      header: () => <Trans i18nKey="company_name" />,
      cell: ({ cell }) => <CompanyTableCell cell={cell} />,
    },
    updatedAt: {
      header: () => <Trans i18nKey="last_updated" />,
      cell: ({ cell }) => <UpdatedAtTableCell cell={cell} />,
    },
    id: {
      header: () => <Trans i18nKey="database_id" />,
      cell: ({ cell }) => <DatabaseIDTableCell cell={cell} />,
      enableSorting: false,
    },
    signingProcedure: {
      header: () => <Trans i18nKey="signing_procedure" />,
      cell: ({ cell }) => <SigningProcedureTableCell cell={cell} />,
    },
    sharesPPS: {
      header: () => <Trans i18nKey="price_per_share_abbr" />,
      cell: ({ cell }) => <SharesPPSTableCell cell={cell} />,
      enableSorting: false,
    },
    state: {
      header: () => <Trans i18nKey="state" />,
      cell: ({ cell }) => <TransactionStateTableCell cell={cell} />,
      enableSorting: false,
    },
  };

  const tableData = useMemo(
    () => transformTransactionsQueryToData(data),
    [data],
  );

  const onRowClick = useCallback(
    (row: Row<Record<string, unknown>>) => {
      const { id } = row.original as TransactionsTableNodeFragment;
      push(`/transactions/${id}`);
    },
    [push],
  );

  const renderRowActions = useCallback(
    (row: Row<Record<string, unknown>>) => (
      <TransactionsTableRowActions
        transaction={row.original as TransactionsTableNodeFragment}
      />
    ),
    [],
  );

  if (!data) return null;

  if (tableData.transactions.length === 0) {
    return <Text>{t`user_active_transactions_table_empty_state`}</Text>;
  }

  return (
    <SimpleTable<
      SimpleTableQueryVariables,
      UserActiveTransactionsTableColumnType[],
      UserActiveTransactionsTableDataType[]
    >
      tableStyleProps={{ border: `solid 1px #D5D5D5`, borderTop: `unset` }}
      page={variables.page as number}
      perPage={TABLE_PER_PAGE}
      sort={sort}
      setSort={setSort as Dispatch<SetStateAction<TableSortState | undefined>>}
      columns={columns}
      setVariables={setVariables}
      tableData={tableData.transactions}
      pageInfo={tableData.pageInfo}
      totalCount={tableData.totalCount}
      renderRowActions={renderRowActions}
      onRowClick={onRowClick}
      error={data.error}
      loading={!tableData.pageInfo && data.fetching}
    />
  );
}

export default UserActiveTransactionsTable;

import { useTranslation } from "react-i18next";

import {
  useMultiEntityExperienceEnabled,
  useSellerExternalAccountEnabled,
} from "@/modules/LaunchDarkly";
import { Skeleton } from "@/modules/Skeleton";
import { Table } from "@/modules/Table";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CaretRight } from "@phosphor-icons/react";

import UserDetailCardSkeleton from "./UserDetailCardSkeleton";

export default function UserMainPanelSkeleton() {
  const { t } = useTranslation();
  const sellerExternalAccountEnabled = useSellerExternalAccountEnabled();
  const multiEntityExperienceEnabled = useMultiEntityExperienceEnabled();

  return (
    <VStack gap={4}>
      <UserDetailCardSkeleton />
      {sellerExternalAccountEnabled && (
        <Card w="full">
          <CardHeader>
            <HStack justifyContent="space-between">
              <Text textStyle="colfax-22-medium">{t`user_bank_accounts_header`}</Text>
              <Skeleton h={8} w={20} />
            </HStack>
          </CardHeader>
          <CardBody>
            <VStack w="full" spacing={4} maxW="50%">
              <Skeleton h={20} w={580} />
            </VStack>
          </CardBody>
        </Card>
      )}
      {multiEntityExperienceEnabled && (
        <Card w="full">
          <CardHeader as={HStack} gap={4} alignItems="center">
            <Text textStyle="colfax-22-medium">{t`users_entities`}</Text>
          </CardHeader>
          <CardBody>
            <Table.Skeleton />
          </CardBody>
        </Card>
      )}
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">
            {t(`user_active_listings_header`)}
          </Text>
          <Box flexGrow={1} />
          <Button variant="outline" size="md">
            <HStack>
              <Text textStyle="colfax-12-medium">{t`user_active_listings_table_action_button`}</Text>
              <CaretRight size={14} />
            </HStack>
          </Button>
        </CardHeader>
        <CardBody>
          <Table.Skeleton />
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader as={HStack} gap={4} alignItems="center">
          <Text textStyle="colfax-22-medium">
            {t(`user_active_transactions_header`)}
          </Text>
          <Box flexGrow={1} />
          <Button variant="outline" size="md">
            <HStack>
              <Text textStyle="colfax-12-medium">{t`user_active_transactions_table_action_button`}</Text>
              <CaretRight size={14} />
            </HStack>
          </Button>
        </CardHeader>
        <CardBody>
          <Table.Skeleton />
        </CardBody>
      </Card>
    </VStack>
  );
}

import { InvestorStatus, UserRole, InvestorType } from "@/graphql";
import { t } from "i18next";

export const investorStatusTranslations: Record<InvestorStatus, () => string> =
  {
    [InvestorStatus.Individual]: () => t(`individual`),
    [InvestorStatus.Institutional]: () => t(`institutional`),
  };

export const roleTranslations: Record<UserRole, () => string> = {
  [UserRole.Admin]: () => t(`admin`),
  [UserRole.Buyer]: () => t(`buyer`),
  [UserRole.Seller]: () => t(`seller`),
  [UserRole.Signer]: () => t(`signer`),
  [UserRole.Trader]: () => t(`trader`),
  [UserRole.Viewer]: () => t(`viewer`),
};

export const investorTypeTranslations: Record<InvestorType, () => string> = {
  [InvestorType.Broker]: () => t(`broker`),
  [InvestorType.Seller]: () => t(`seller`),
  [InvestorType.Trader]: () => t(`trader`),
  [InvestorType.UnaccreditedSeller]: () => t(`unaccredited_seller`),
};

import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CompanySortField,
  SortDirection,
  useAddToUserWatchlistMutation,
  useUsersCompanyWatchlistComboboxCompaniesQuery,
  WatchlistCompanyFragment,
} from "@/graphql";
import { Combobox } from "@/modules/Form";
import { DEFAULT_FETCH_LIMIT } from "@/constants";
import { Box, Image } from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useColors } from "@/modules/Theme";

type SearchForm = {
  searchText: string;
};

interface UsersCompanyWatchlistComboboxProps {
  companiesOnWatchlist: WatchlistCompanyFragment[];
  setValueCallback: () => void;
  userId: string;
}

export default function UsersCompanyWatchlistCombobox({
  companiesOnWatchlist,
  setValueCallback,
  userId,
}: UsersCompanyWatchlistComboboxProps) {
  const { control, register, setValue } = useForm<SearchForm>();
  const [variables, setVariables] = useState({
    first: DEFAULT_FETCH_LIMIT,
    sortBy: { field: CompanySortField.Name, direction: SortDirection.Asc },
    filterBy: { searchText: `` },
  });
  const { t } = useTranslation();
  const [grey200] = useColors([`grey.200`]);

  const [{ data, fetching }] = useUsersCompanyWatchlistComboboxCompaniesQuery({
    variables,
  });

  const [_, addToUserWatchlist] = useAddToUserWatchlistMutation();

  const searchText = useWatch({ control, name: `searchText` });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((search: string) => {
      setVariables((currentVariables) => ({
        ...currentVariables,
        filterBy: { searchText: search },
      }));
    }, 500),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchText);

    return () => debouncedSearch.cancel();
  }, [searchText, debouncedSearch]);

  const companyOnWatchlistIds = companiesOnWatchlist.map(
    (company) => company.id,
  );

  const companies = useMemo(() => {
    if (!data || !data.companies || !data.companies.edges) return [];

    return data.companies.edges
      ?.map((edge) => edge.node)
      .filter((company) => !companyOnWatchlistIds.includes(company.id));
  }, [companyOnWatchlistIds, data]);

  const handleSetValue = (company: WatchlistCompanyFragment) => {
    addToUserWatchlist({ userId, companyId: company.id }).then(() => {
      setValue(`searchText`, ``);
      setValueCallback();
    });
  };

  return (
    <Combobox
      placeholder={t(`search_companies`)}
      getItemKey={(company) => company.id}
      getItemLabel={(company) => company.name}
      getItemLogo={(company) =>
        company.logoUrl ? (
          <Image
            h={6}
            w={6}
            objectFit="contain"
            src={company.logoUrl}
            alt={company.name}
          />
        ) : (
          <Box h={6} w={6} />
        )
      }
      getItems={() => companies}
      setValue={handleSetValue}
      isLoading={fetching}
      leftElement={<MagnifyingGlass size={20} color={grey200} />}
      {...register(`searchText`)}
    />
  );
}

import { Trans, useTranslation } from "react-i18next";

import { TableColumns } from "@/modules/Table";
import { Status } from "@/modules/Status";
import { CellType, UserActiveListingsTableDataType } from "./types";

function ListingSolicitedStateTableCell({ cell }: CellType) {
  const { t } = useTranslation();
  const { solicited } = cell.getContext().row.original;

  const indicatorColor = `skyBlue.600`;

  const solicitedText = solicited ? t(`solicited`) : t(`unsolicited`);

  return (
    <Status.Indicator
      text={solicitedText}
      indicatorProps={{ bg: indicatorColor }}
    />
  );
}

const columns: TableColumns<UserActiveListingsTableDataType> = {
  displayId: {
    header: () => <Trans i18nKey="id" />,
    enableSorting: false,
  },
  companyName: {
    header: () => <Trans i18nKey="company" />,
    enableSorting: false,
  },
  securitySpecialistFullName: {
    header: () => <Trans i18nKey="security_specialist" />,
    enableSorting: false,
  },
  shares: {
    header: () => <Trans i18nKey="shares_pps" />,
    enableSorting: false,
  },

  insertedAt: {
    header: () => <Trans i18nKey="created_on" />,
    enableSorting: false,
  },
  solicited: {
    header: () => <Trans i18nKey="solicited" />,
    enableSorting: false,
    cell: ({ cell }) => <ListingSolicitedStateTableCell cell={cell} />,
  },
};

export default columns;

export default function getMembershipAgreementSigned(
  agreedToCustomerAgreement: boolean | null | undefined,
  institutionId: string | null | undefined,
  institutionMembershipAgreementSigned: boolean | null | undefined,
  membershipAgreementSigned: boolean | null,
) {
  if (agreedToCustomerAgreement) {
    return true;
  }

  if (institutionId) {
    return institutionMembershipAgreementSigned;
  }

  return membershipAgreementSigned;
}

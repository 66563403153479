import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { HiiveModal } from "@/modules/Modal";

interface EnrollMfaErrorModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

function EnrollMfaErrorModal({
  isOpen,
  handleClose,
}: EnrollMfaErrorModalProps) {
  const { t } = useTranslation();

  return (
    <HiiveModal
      size="lg"
      isOpen={isOpen}
      title={t(`enrollment_error`)}
      body={
        <VStack alignItems="flex-start" gap={4}>
          <Text>{t(`enrollment_error_description`)}</Text>
        </VStack>
      }
      onConfirm={handleClose}
      onClose={handleClose}
      confirmText={t(`close_mfa_setup`)}
    />
  );
}

export default EnrollMfaErrorModal;

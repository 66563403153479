import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react";

import { HiiveModal } from "@/modules/Modal";
import { CopySimple } from "@phosphor-icons/react";
import { useCustomToast } from "@/modules/Toast";
import { useRouter } from "next/router";

interface RecoveryCodeModalProps {
  recoveryCode: string;
  isOpen: boolean;
}

function RecoveryCodeModal({ recoveryCode, isOpen }: RecoveryCodeModalProps) {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const { reload } = useRouter();
  const { onCopy } = useClipboard(recoveryCode || ``);

  const handleCopy = () => {
    onCopy();
    successToast(t(`copied_to_clipboard`));
  };

  const handleDone = () => {
    reload();
  };

  return (
    <HiiveModal
      size="lg"
      isOpen={isOpen}
      title={t(`setup_complete`)}
      body={
        <VStack alignItems="center" gap={4}>
          <Text>
            <Trans
              i18nKey="setup_complete_description_with_recovery_code"
              components={{ bold: <strong /> }}
            />
          </Text>
          <VStack alignItems="flex-start" width="full">
            <Text>
              <Trans
                i18nKey="your_recovery_code_bold"
                components={{ bold: <strong /> }}
              />
            </Text>
            <InputGroup>
              <Input isReadOnly value={recoveryCode} />
              <InputRightElement>
                <Box
                  alignItems="center"
                  backgroundColor="grey.50"
                  borderRadius="100%"
                  cursor="pointer"
                  display="flex"
                  height="1.625rem"
                  justifyContent="center"
                  width="1.625rem"
                  flexShrink={0}
                  onClick={handleCopy}
                  _hover={{ backgroundColor: `grey.100` }}
                >
                  <CopySimple size={14} />
                </Box>
              </InputRightElement>
            </InputGroup>
          </VStack>
        </VStack>
      }
      onConfirm={handleDone}
      onClose={handleDone}
      confirmText={t(`refresh_page`)}
    />
  );
}

export default RecoveryCodeModal;

import { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Menu,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Table } from "@/modules/Table";

import {
  UserMultiFactorAuthentications,
  UserMultiFactorAuthenticationStatus,
  useUserMultiFactorAuthenticationsCardRemoveUserMultiFactorAuthenticationMutation,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { match } from "ts-pattern";
import { useCustomToast } from "@/modules/Toast";
import { UserDetailsContext } from "@/features/Users";

function ModalBody({ app, sms }: UserMultiFactorAuthentications) {
  const { t } = useTranslation();
  const suffixText = match({ app, sms })
    .with(
      {
        app: UserMultiFactorAuthenticationStatus.Active,
        sms: UserMultiFactorAuthenticationStatus.Active,
      },
      () => (
        <Text as="span">
          <Trans
            i18nKey="sms_and_authenticator_app"
            components={{ b: <strong /> }}
          />
        </Text>
      ),
    )
    .with({ app: UserMultiFactorAuthenticationStatus.Active }, () => (
      <Text as="span" fontWeight="bold" textTransform="lowercase">
        {t(`authenticator_app`)}
      </Text>
    ))
    .with({ sms: UserMultiFactorAuthenticationStatus.Active }, () => (
      <Text as="span" fontWeight="bold">
        {t(`sms`)}
      </Text>
    ))
    .otherwise(() => null);

  return (
    <Text>
      {t(`unenroll_mfa_confirmation`)} {suffixText}.
    </Text>
  );
}

export default function UserMultiFactorAuthenticationsCardActions() {
  const { t } = useTranslation();
  const [title, setTitle] = useState(``);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { errorToast, successToast } = useCustomToast();

  const { user } = useContext(UserDetailsContext);

  const handleOpenRemoveUserMFAModal = () => {
    setTitle(t(`remove_mfa`));
    onOpen();
  };

  const [, disableUserMultiFactorAuthentication] =
    useUserMultiFactorAuthenticationsCardRemoveUserMultiFactorAuthenticationMutation();

  if (!user) return null;

  const { id: userId, multiFactorAuthentications } = user;

  const onConfirm = async () => {
    const { data } = await disableUserMultiFactorAuthentication({
      input: { userId },
    });

    if (data?.disableUserMultiFactorAuthentication?.user?.id) {
      successToast();
    } else {
      errorToast();
    }
    onClose();
  };

  return (
    <>
      <Menu>
        <Table.ActionButton showTitle />
        <MenuList>
          <MenuGroup>
            <MenuItem onClick={handleOpenRemoveUserMFAModal}>
              <Text textStyle="colfax-14-medium" textColor="red.900">
                {t(`remove_mfa`)}
              </Text>
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <ConfirmModal
        title={title}
        body={<ModalBody {...multiFactorAuthentications} />}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => onConfirm()}
      />
    </>
  );
}

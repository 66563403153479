import parsePhoneNumber from "libphonenumber-js";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  getMembershipAgreementSigned,
  LowercasedUserStatusValues,
  MappedUser,
  UserDetailsContext,
} from "@/features/Users";
import { InvestorType, UserStatus } from "@/graphql";
import { CardTable } from "@/modules/CardTable";
import { CopyToClipboard } from "@/modules/CopyToClipboard";
import { Link } from "@/modules/Navigation";
import { Status } from "@/modules/Status/";
import { IndicatorColor } from "@/modules/Table";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import UsersTableRowActions from "./UsersTableRowActions";

interface UserDetailProfileProps {
  user: MappedUser;
}

function UserDetailProfile({ user }: UserDetailProfileProps) {
  const { t } = useTranslation();

  const { email, id, phoneNumber } = user;

  const parsedPhoneNumber = phoneNumber
    ? parsePhoneNumber(phoneNumber as string)
    : null;

  return (
    <VStack alignItems="flex-start">
      {email && (
        <CopyToClipboard
          label={t(`email`)}
          value={email}
          link={{ href: `mailto:${email}`, label: email }}
        />
      )}
      <CopyToClipboard label={t(`user_id`)} value={id} />
      <CopyToClipboard
        label={t(`phone`)}
        value=""
        {...(phoneNumber && {
          value: phoneNumber || ``,
          link: parsedPhoneNumber
            ? {
                href: `callto:${parsedPhoneNumber.number}`,
                label: parsedPhoneNumber.formatInternational(),
              }
            : null,
        })}
      />
    </VStack>
  );
}

interface UserDetailInvestorInfoProps {
  user: MappedUser;
}

function UserDetailInvestorInfo({ user }: UserDetailInvestorInfoProps) {
  const { t } = useTranslation();

  const {
    country,
    institution,
    investorStatus,
    investorType,
    translatedRoles,
    translatedInvestorStatus,
    translatedInvestorType,
  } = user;
  const countryName = institution?.country?.name || country?.name;

  return (
    <Card variant="table">
      <CardHeader>{t(`user_info`)}</CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          <Box>
            <Text textStyle="colfax-14-medium" color="grey.600">
              {t(`country`)}
            </Text>
            {countryName ? <Text>{countryName}</Text> : <Status.Empty />}
          </Box>
          {investorStatus && (
            <Box>
              <Text textStyle="colfax-14-medium" color="grey.600">
                {t(`investor_status`)}
              </Text>
              <Text textTransform="capitalize">{translatedInvestorStatus}</Text>
            </Box>
          )}
          <Box>
            <Text textStyle="colfax-14-medium" color="grey.600">
              {t(`roles`)}
            </Text>
            <Text>
              {translatedRoles.map(
                (translatedRole, index) =>
                  `${translatedRole}${
                    index !== translatedRoles.length - 1 ? `, ` : ``
                  }`,
              )}
            </Text>
          </Box>
          {investorType && (
            <Box>
              <Text textStyle="colfax-14-medium" color="grey.600">
                {t(`investor_type`)}
              </Text>
              <Text>{translatedInvestorType}</Text>
            </Box>
          )}
          {institution && (
            <Box>
              <Text textStyle="colfax-14-medium" color="grey.600">
                {t(`institution`)}
              </Text>
              <Link href={`/institutions/${institution.id}`}>
                <Text textStyle="colfax-16-medium" textDecoration="underline">
                  {institution.legalName}
                </Text>
              </Link>
            </Box>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
}

interface UserDetailStatusProps {
  user: MappedUser;
}

function UserDetailStatus({ user }: UserDetailStatusProps) {
  const { t } = useTranslation();

  const {
    accredited,
    agreedToCustomerAgreement,
    identityVerified,
    institution,
    institutionId,
    investorType,
    membershipAgreementSigned,
    onboardingComplete,
    suitable,
  } = user;

  const isMembershipAgreementSigned = getMembershipAgreementSigned(
    agreedToCustomerAgreement,
    institutionId,
    institution?.membershipAgreementSigned,
    membershipAgreementSigned,
  );

  const showAccreditationAndSuitability =
    investorType !== InvestorType.UnaccreditedSeller;

  const isAccredited = institutionId ? institution?.accredited : accredited;

  const isSuitable = institutionId ? institution?.suitable : suitable;

  return (
    <Card variant="table">
      <CardHeader>{t(`status`)}</CardHeader>
      <CardBody>
        <HStack>
          <Status.Generic booleanFlag={onboardingComplete} />
          <Text>{t(`onboarding_complete`)}</Text>
        </HStack>
        <HStack>
          <Status.MembershipAgreement
            membershipAgreementSigned={isMembershipAgreementSigned}
            investorType={investorType}
          />
          <Text>{t(`customer_agreement`)}</Text>
        </HStack>
        <HStack>
          <Status.IdentityVerification
            identityVerified={identityVerified}
            investorType={investorType}
            isInstitutionUser={!!institutionId}
          />
          <Text>{t(`identity_verification`)}</Text>
        </HStack>
        {showAccreditationAndSuitability && (
          <>
            <HStack>
              <Status.Accreditation
                isAccredited={isAccredited}
                investorType={investorType}
              />
              <Text>{t(`accreditation`)}</Text>
            </HStack>
            <HStack>
              <Status.Suitability
                isSuitable={isSuitable}
                investorType={investorType}
              />
              <Text>{t(`suitability`)}</Text>
            </HStack>
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default function UserDetailCard() {
  const { user } = useContext(UserDetailsContext);
  const { t } = useTranslation();

  if (!user) return null;

  const { name, status } = user;

  const indicatorColor = match(status)
    .with(UserStatus.Approved, () => IndicatorColor.ACTIVE)
    .with(UserStatus.AwaitingApproval, () => IndicatorColor.PENDING)
    .with(UserStatus.Deactivated, () => IndicatorColor.ARCHIVED)
    .otherwise(() => IndicatorColor.DISABLED);

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{name}</Text>
        <Box flexGrow={1} />
        <Status.Indicator
          text={t(status.toLowerCase() as LowercasedUserStatusValues)}
          fontWeight={500}
          indicatorProps={{ bg: indicatorColor }}
        />
        <UsersTableRowActions user={user} showActionButtonTitle />
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" gap={8}>
          <UserDetailProfile user={user} />
          <CardTable w="full" columns={2}>
            <UserDetailInvestorInfo user={user} />
            <UserDetailStatus user={user} />
          </CardTable>
        </VStack>
      </CardBody>
    </Card>
  );
}

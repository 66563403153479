import {
  Accordion,
  AccordionIcon,
  AccordionButton,
  AccordionItem,
  HStack,
  Text,
  SimpleGrid,
  GridItem,
  AccordionPanel,
  VStack,
} from "@chakra-ui/react";

import { DateTime } from "luxon";
import { useUserTrustedContactPersonQuery } from "@/graphql";
import { Trans, useTranslation } from "react-i18next";
import UserTrustedContactPersonSkeleton from "./UserTrustedContactPersonSkeleton";

interface UserTrustedContactPersonProps {
  userId: string;
}

export default function UserTrustedContactPerson({
  userId,
}: UserTrustedContactPersonProps) {
  const { t } = useTranslation();
  const [{ data, fetching }] = useUserTrustedContactPersonQuery({
    variables: { userId },
  });

  if (fetching || !data) return <UserTrustedContactPersonSkeleton />;

  const trustedContactPerson = data.user?.trustedContactPerson;

  return (
    <Accordion w="full" defaultIndex={[0]} allowToggle>
      <AccordionItem>
        <AccordionButton>
          <Text textStyle="colfax-22-medium">
            {t(`trusted_contact_person`)}
          </Text>
          <HStack>
            {trustedContactPerson?.updatedAt && (
              <Text>
                {DateTime.fromISO(
                  trustedContactPerson?.updatedAt,
                ).toLocaleString(DateTime.DATETIME_FULL)}
              </Text>
            )}
            <AccordionIcon />
          </HStack>
        </AccordionButton>
        <AccordionPanel>
          {!!trustedContactPerson ? (
            <VStack alignItems="flex-start" spacing={6}>
              <Text textStyle="heading-lg">
                <Trans
                  i18nKey="add_trusted_contact_person"
                  component={{ i: <i /> }}
                />
                *
              </Text>
              <VStack
                alignItems="flex-start"
                spacing={2}
                bg="grey.50"
                px={4}
                py={2}
                borderRadius="sm"
              >
                <Text>{t`trusted_contact_person_explanation_one`}</Text>
                <Text>{t`trusted_contact_person_explanation_two`}</Text>
              </VStack>
              <SimpleGrid gridTemplateColumns="1fr" rowGap={4}>
                <GridItem>
                  <Trans
                    i18nKey="contact_provided_answer"
                    components={{ b: <strong /> }}
                    values={{
                      answer: trustedContactPerson?.isContactProvided
                        ? t`yes`
                        : t`no`,
                    }}
                  />
                </GridItem>
                {!!trustedContactPerson?.isContactProvided && (
                  <SimpleGrid gridTemplateColumns="1fr" rowGap={4}>
                    <GridItem>
                      <VStack alignItems="flex-start" gap={1}>
                        <Text fontWeight={500}>{t`name`}</Text>
                        <Text>{`${trustedContactPerson?.contactInfo?.firstName} ${trustedContactPerson?.contactInfo?.lastName}`}</Text>
                      </VStack>
                    </GridItem>
                    <GridItem>
                      <VStack alignItems="flex-start" gap={1}>
                        <Text fontWeight={500}>{t`telephone`}</Text>
                        <Text>
                          {trustedContactPerson?.contactInfo?.phoneNumber}
                        </Text>
                      </VStack>
                    </GridItem>
                    <GridItem>
                      <VStack alignItems="flex-start" gap={1}>
                        <Text fontWeight={500}>{t`email`}</Text>
                        <Text>{trustedContactPerson?.contactInfo?.email}</Text>
                      </VStack>
                    </GridItem>
                    <GridItem>
                      <VStack alignItems="flex-start" gap={1}>
                        <Text fontWeight={500}>{t`relationship`}</Text>
                        <Text>
                          {trustedContactPerson?.contactInfo?.relationship}
                        </Text>
                      </VStack>
                    </GridItem>
                  </SimpleGrid>
                )}
              </SimpleGrid>
            </VStack>
          ) : (
            <Text>{t`trusted_contact_person_empty_state`}</Text>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

import { useTranslation } from "react-i18next";

import { FeeDiscountsCardSkeleton } from "@/modules/FeeDiscountsCard";
import { Skeleton } from "@/modules/Skeleton";
import { useColors } from "@/modules/Theme";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as LinkIcon } from "@phosphor-icons/react";

export default function UserRightPanelSkeleton() {
  const { t } = useTranslation(`users`);
  const [grey600] = useColors([`grey.600`]);

  return (
    <VStack gap={4}>
      <Card w="full">
        <CardHeader textStyle="colfax-22-medium">
          {t(`deactivation_reason`)}
        </CardHeader>
        <CardBody>
          <Skeleton h={22} w="full" />
          <Text textStyle="colfax-14-regular" color="grey.600" mt={2}>
            {t(`confirm_deactivate_textarea_helper_text`)}
          </Text>
        </CardBody>
      </Card>
      <Card w="full">
        <CardHeader textStyle="colfax-22-medium">
          {t(`user_documents`)}
        </CardHeader>
        <CardBody>
          <HStack>
            <LinkIcon size={18} color={grey600} />
            <Skeleton h={5} w="full" />
          </HStack>
        </CardBody>
      </Card>
      <FeeDiscountsCardSkeleton />
    </VStack>
  );
}

import { WarningCircle } from "@phosphor-icons/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Image, Text, VStack } from "@chakra-ui/react";

import { useVerifyAppMfa } from "@/modules/Frontegg";
import { HiiveModal } from "@/modules/Modal";
import { ErrorCallOut } from "@/modules/Error";
import CodeInput, { PIN_LENGTH } from "./CodeInput";

interface EnrollAppMfaModalProps {
  qrCode: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: (recoveryCode: string) => void;
}

function EnrollAppMfaModal({
  qrCode,
  isOpen,
  handleClose,
  handleSuccess,
}: EnrollAppMfaModalProps) {
  const { t } = useTranslation();
  const [verifyMfa, { loading, error }] = useVerifyAppMfa();
  const [pinValue, setPinValue] = useState<string>(``);

  const handleComplete = useCallback(
    async (value: string) => {
      const { data } = await verifyMfa({ token: value });
      if (data) {
        const { recoveryCode } = data;
        handleSuccess(recoveryCode || ``);
      }
    },
    [handleSuccess, verifyMfa],
  );

  const handleConfirm = () => {
    handleComplete(pinValue);
  };

  return (
    <HiiveModal
      size="lg"
      isOpen={isOpen}
      title={t(`setup_authenticator_app`)}
      body={
        <VStack alignItems="center" gap={4}>
          <Text>{t(`set_up_authenticator_app_description`)}</Text>
          <Image src={qrCode} />
          <CodeInput
            disabled={loading}
            onChange={(value: string) => setPinValue(value)}
            onComplete={handleComplete}
          />
          {!!error && (
            <ErrorCallOut>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </ErrorCallOut>
          )}
        </VStack>
      }
      disableConfirm={pinValue.length !== PIN_LENGTH || loading}
      onConfirm={handleConfirm}
      onClose={handleClose}
      confirmText={t(`verify`)}
      loading={loading}
    />
  );
}

export default EnrollAppMfaModal;

import isNil from "lodash/isNil";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { InputWrapper } from "@/modules/Form";
import {
  useUsersTableRowActionsActivateUserMutation,
  useUsersTableRowActionsApproveIndividualMutation,
  useUsersTableRowActionsArchiveUserMutation,
  useUsersTableRowActionsDeactivateUserMutation,
  useUsersTableRowActionsIgnoreIndividualMutation,
  UserDetailFragment,
  UsersTableNodeFragment,
  UserStatus,
  useUsersTableRowActionsUnignoreIndividualMutation,
  InvestorType,
} from "@/graphql";
import { ConfirmModal } from "@/modules/Modal";
import { Table } from "@/modules/Table";
import {
  Box,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowSquareOut, Circle } from "@phosphor-icons/react";

import { useCustomToast } from "@/modules/Toast";
import {
  UsersTableRowActionInputs,
  UsersTableRowActionModalBodyType,
} from "@/features/Users";

interface UsersTableRowActionsProps {
  user: UsersTableNodeFragment | UserDetailFragment;
  showActionButtonTitle?: boolean;
}

export default function UsersTableRowActions({
  user,
  showActionButtonTitle,
}: UsersTableRowActionsProps) {
  const [title, setTitle] = useState(``);
  const [modalBodyType, setModalBodyType] =
    useState<UsersTableRowActionModalBodyType>(
      UsersTableRowActionModalBodyType.APPROVE,
    );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  const { t } = useTranslation(`users`);
  const { register, handleSubmit } = useForm<UsersTableRowActionInputs>();

  const [_activateUserResult, activateUser] =
    useUsersTableRowActionsActivateUserMutation();
  const [_approveIndividualResult, approveIndividual] =
    useUsersTableRowActionsApproveIndividualMutation();
  const [_archiveUserResult, archiveUser] =
    useUsersTableRowActionsArchiveUserMutation();
  const [_deactivateUserResult, deactivateUser] =
    useUsersTableRowActionsDeactivateUserMutation();
  const [_ignoreIndividualResult, ignoreIndividual] =
    useUsersTableRowActionsIgnoreIndividualMutation();
  const [_unignoreIndividualResult, unignoreIndividual] =
    useUsersTableRowActionsUnignoreIndividualMutation();

  const { errorToast, successToast } = useCustomToast();

  const {
    id: userId,
    status,
    identityCheckInquiryId,
    name,
    email,
    institution,
    holdings,
  } = user;

  const renderModalBody = () =>
    match(modalBodyType)
      .with(UsersTableRowActionModalBodyType.ACTIVATE, () => (
        <Text>
          <Trans
            i18nKey="confirm_activate_body"
            components={[<strong key="bold" />]}
          />
        </Text>
      ))
      .with(UsersTableRowActionModalBodyType.DEACTIVATE, () => (
        <InputWrapper
          name="deactivationReason"
          label={t(`confirm_deactivate_textarea_label`)}
          helperText={t(`confirm_deactivate_textarea_helper_text`)}
        >
          <Textarea {...register(`deactivationReason`)} />
        </InputWrapper>
      ))
      .with(UsersTableRowActionModalBodyType.ARCHIVE, () => (
        <Box>
          <Text>
            <Trans
              i18nKey="confirm_archive_body"
              components={[<strong key="bold" />]}
            />
            <br /> <br />
          </Text>
          <Text>
            <b>
              <Trans i18nKey="name" />:{` `}
            </b>
            {name}
          </Text>
          <Text>
            <b>
              <Trans i18nKey="email" />:{` `}
            </b>
            {email}
          </Text>
          {institution && (
            <Text>
              <b>
                <Trans i18nKey="institution" />:{` `}
              </b>
              {institution.legalName}
            </Text>
          )}
        </Box>
      ))
      .with(UsersTableRowActionModalBodyType.IGNORE, () => (
        <Text>
          <Trans
            i18nKey="confirm_ignore_body"
            components={[<strong key="bold" />]}
          />
        </Text>
      ))
      .with(UsersTableRowActionModalBodyType.UNIGNORE, () => (
        <Text>
          <Trans
            i18nKey="confirm_remove_ignore_body"
            components={[<strong key="bold" />]}
          />
        </Text>
      ))
      .with(UsersTableRowActionModalBodyType.APPROVE, () => (
        <Text>
          <Trans
            i18nKey="confirm_approve_user_body"
            components={[<strong key="bold" />]}
          />
        </Text>
      ))
      .exhaustive();

  const handleActivate = () => {
    setTitle(t(`confirm_activate_title`));
    setModalBodyType(UsersTableRowActionModalBodyType.ACTIVATE);
    onOpen();
  };

  const handleDeactivate = () => {
    setTitle(t(`confirm_deactivate_title`));
    setModalBodyType(UsersTableRowActionModalBodyType.DEACTIVATE);
    onOpen();
  };

  const handleArchive = () => {
    setTitle(t(`confirm_archive_title`));
    setModalBodyType(UsersTableRowActionModalBodyType.ARCHIVE);
    onOpen();
  };

  const handleIgnore = () => {
    setTitle(t(`confirm_ignore_title`));
    setModalBodyType(UsersTableRowActionModalBodyType.IGNORE);
    onOpen();
  };

  const handleUnignore = () => {
    setTitle(t(`confirm_remove_ignore_title`));
    setModalBodyType(UsersTableRowActionModalBodyType.UNIGNORE);
    onOpen();
  };

  const handleApprove = () => {
    setTitle(t(`confirm_approve_user_title`));
    setModalBodyType(UsersTableRowActionModalBodyType.APPROVE);
    onOpen();
  };

  const deactivateUserItem = (
    <MenuItem
      icon={<Circle size={7} color="#DD0000" weight="fill" />}
      onClick={handleDeactivate}
    >
      {t(`deactivate_user`)}
    </MenuItem>
  );

  const archiveUserItem = (
    <MenuItem color="archived" onClick={handleArchive}>
      {t(`archive_user`)}
    </MenuItem>
  );

  const ignoreUserItem = (
    <MenuItem onClick={handleIgnore}>{t(`ignore_user`)}</MenuItem>
  );

  const isApprovable =
    user.investorType !== InvestorType.UnaccreditedSeller ||
    (holdings && holdings.length !== 0);

  const actions = match(status)
    .with(UserStatus.AwaitingApproval, () => (
      <>
        <MenuGroup>
          <MenuItem
            icon={<Circle size={7} color="#00924F" weight="fill" />}
            onClick={handleApprove}
            isDisabled={!isApprovable}
          >
            {t(`approve_user`)}
          </MenuItem>
          {deactivateUserItem}
        </MenuGroup>
        <MenuDivider />
        <MenuGroup>
          {ignoreUserItem}
          {archiveUserItem}
        </MenuGroup>
      </>
    ))
    .with(UserStatus.Deactivated, () => (
      <>
        <MenuGroup>
          <MenuItem
            icon={<Circle size={7} color="#00924F" weight="fill" />}
            onClick={handleActivate}
          >
            {t(`activate_user`)}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup>
          {ignoreUserItem}
          {archiveUserItem}
        </MenuGroup>
      </>
    ))
    .with(UserStatus.Incomplete, () => (
      <>
        <MenuGroup>{deactivateUserItem}</MenuGroup>
        <MenuDivider />
        <MenuGroup>
          {ignoreUserItem}
          {archiveUserItem}
        </MenuGroup>
      </>
    ))
    .with(UserStatus.Approved, () => (
      <>
        <MenuGroup>{deactivateUserItem}</MenuGroup>
        <MenuDivider />
        <MenuGroup>{archiveUserItem}</MenuGroup>
      </>
    ))
    .with(UserStatus.Ignored, () => (
      <MenuGroup>
        <MenuItem onClick={handleUnignore}>{t(`remove_ignore`)}</MenuItem>
      </MenuGroup>
    ))
    .exhaustive();

  const onConfirm = (data: UsersTableRowActionInputs) => {
    const { deactivationReason } = data;

    match(modalBodyType)
      .with(UsersTableRowActionModalBodyType.ACTIVATE, async () => {
        const { data } = await activateUser({ userId });
        if (data?.activateUser?.user?.id) {
          successToast();
        } else {
          errorToast();
        }
        onClose();
      })
      .with(UsersTableRowActionModalBodyType.DEACTIVATE, async () => {
        const { data } = await deactivateUser({ userId, deactivationReason });
        if (data?.deactivateUser?.user?.id) {
          successToast();
        } else {
          errorToast();
        }
        onClose();
      })
      .with(UsersTableRowActionModalBodyType.ARCHIVE, async () => {
        const { data } = await archiveUser({ userId });
        if (data?.archiveUser?.user?.id) {
          successToast();
        } else {
          errorToast();
        }
        onClose();

        if (router.pathname !== `/users`) {
          await router.replace(`/users`);
        }
      })
      .with(UsersTableRowActionModalBodyType.IGNORE, async () => {
        const { data } = await ignoreIndividual({ userId });
        if (data?.ignoreIndividual?.user?.id) {
          successToast();
        } else {
          errorToast();
        }
        onClose();
      })
      .with(UsersTableRowActionModalBodyType.UNIGNORE, async () => {
        const { data } = await unignoreIndividual({ userId });
        if (data?.unignoreIndividual?.user?.id) {
          successToast();
        } else {
          errorToast();
        }
        onClose();
      })
      .with(UsersTableRowActionModalBodyType.APPROVE, async () => {
        const { data, error } = await approveIndividual({ userId });
        if (data?.approveIndividual?.user?.id) {
          successToast();
        } else {
          errorToast(error?.toString());
        }
        onClose();
      })
      .exhaustive();
  };

  if (isNil(actions) && isNil(identityCheckInquiryId)) {
    return null;
  }

  return (
    <>
      <Menu>
        <Table.ActionButton showTitle={showActionButtonTitle} />
        <MenuList>
          {actions}
          {identityCheckInquiryId && (
            <>
              <MenuDivider />
              <MenuGroup>
                <MenuItem
                  as="a"
                  href={`${process.env.NEXT_PUBLIC_PERSONA_INQUIRIES_URL}/${identityCheckInquiryId}`}
                  target="_blank"
                >
                  {t(`open_in_persona`)}
                  <Box as="span" ml={2}>
                    <ArrowSquareOut size={12} color="#818181" weight="fill" />
                  </Box>
                </MenuItem>
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
      <ConfirmModal
        title={title}
        body={renderModalBody()}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}

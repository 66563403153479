import { useContext } from "react";

import {
  UserDetailsContext,
  UserDetailsProvider,
  UserOverview,
  UserPageNavMenu,
  UserPageSkeleton,
  UserRightPanel,
} from "@/features/Users";
import { ErrorWrapper } from "@/modules/Error";

import { DetailPageLayout } from "@/modules/Layout";
import { VStack } from "@chakra-ui/react";

import UserActiveListingsCard from "./UserActiveListingsCard";
import UserActiveTransactionsCard from "./UserActiveTransactionsCard";

function UserPageMainPanel() {
  return (
    <VStack gap={4}>
      <UserOverview />
      <UserActiveListingsCard />
      <UserActiveTransactionsCard />
    </VStack>
  );
}

function UserPageContent() {
  const { error, fetching, user } = useContext(UserDetailsContext);

  if (fetching) {
    return <UserPageSkeleton />;
  }

  return (
    <ErrorWrapper error={error?.message}>
      <DetailPageLayout
        isTwoColumn
        mainPanel={user && <UserPageMainPanel />}
        tabMenu={user && <UserPageNavMenu userId={user.id} />}
        rightPanel={user && <UserRightPanel />}
      />
    </ErrorWrapper>
  );
}

export default function UserPage({ userId }: { readonly userId: string }) {
  return (
    <UserDetailsProvider userId={userId}>
      <UserPageContent />
    </UserDetailsProvider>
  );
}

import Link from "next/link";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserDetailsContext } from "@/features/Users";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";
import { CaretRight } from "@phosphor-icons/react";

import UserActiveListingsTable from "./UserActiveListingsTable";

function UserActiveListingsCard() {
  const { user } = useContext(UserDetailsContext);
  const { t } = useTranslation();

  if (!user) return null;

  const { id } = user;

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Text textStyle="colfax-22-medium">{t`user_active_listings_header`}</Text>
        <Box flexGrow={1} />
        <Link href={`/listings?sellerId=${id}`} replace>
          <Button variant="outline" size="md">
            <HStack>
              <Text textStyle="colfax-12-medium">{t`user_active_listings_table_action_button`}</Text>
              <CaretRight size={14} />
            </HStack>
          </Button>
        </Link>
      </CardHeader>
      <CardBody>
        <UserActiveListingsTable userId={id} />
      </CardBody>
    </Card>
  );
}

export default UserActiveListingsCard;

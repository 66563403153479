import { useTranslation } from "react-i18next";

import { Button, HStack } from "@chakra-ui/react";

export default function UserPageNavMenuSkeleton() {
  const { t } = useTranslation();
  return (
    <HStack p={4} ml={4} gap={4} bg="white" borderRadius={8} w="fit-content">
      <Button variant="ghost" size="lg">
        {t(`user_overview`)}
      </Button>
      <Button variant="ghost" size="lg">
        {t(`watchlist`)}
      </Button>
      <Button variant="ghost" size="lg">
        {`${t(`accreditation`)}/${t(`suitability`)}`}
      </Button>
    </HStack>
  );
}

import { useContext, useState } from "react";
import { ConfirmModal } from "@/modules/Modal";
import { CompanySearchCombobox } from "@/modules/Company";
import { useForm } from "react-hook-form";
import { useCustomToast } from "@/modules/Toast";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CompanySearchFragment,
  SellerListedHoldingFragment,
  useConvertUnlistedHoldingMutation,
} from "@/graphql";
import { UserDetailsContext } from "@/features/Users";
import { Text } from "@chakra-ui/react";
import { SellerHolding } from "./SellerHoldingsCard";

interface ConfirmConvertHoldingModalProps {
  holding?: SellerHolding;
  open: boolean;
  onClose: () => void;
  currentHoldings: SellerListedHoldingFragment[];
}

type ConvertHoldingInputs = {
  readonly companyId: string;
  readonly companyName: string;
};

enum Step {
  Form,
  Confirmation,
}

const ConvertHoldingValidationSchema = yup.object({
  companyId: yup.string().required(`Company is required`),
  companyName: yup.string().required(),
});

export default function ConfirmConvertHoldingModal({
  holding,
  open,
  onClose,
  currentHoldings,
}: ConfirmConvertHoldingModalProps) {
  const { t } = useTranslation();
  const { user } = useContext(UserDetailsContext);
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues,
  } = useForm<ConvertHoldingInputs>({
    resolver: yupResolver(ConvertHoldingValidationSchema),
    shouldFocusError: false,
  });
  const [step, setStep] = useState(Step.Form);
  const { errorToast, successToast } = useCustomToast();
  const [_, convertUnlistedHolding] = useConvertUnlistedHoldingMutation();

  const companyId = watch(`companyId`);

  const handleCompanySelect = (value: CompanySearchFragment) => {
    setValue(`companyId`, value.id);
    setValue(`companyName`, value.name, { shouldValidate: false });
  };

  const handleClose = () => {
    reset();
    setStep(Step.Form);
    onClose();
  };

  if (!holding || !user || holding?.__typename !== `UnlistedHolding`) {
    return null;
  }

  switch (step) {
    case Step.Form:
      return (
        <ConfirmModal
          title={t(`convert_holding_title`)}
          confirmText={t(`confirm_convert_holding_button`)}
          body={
            <>
              <Text
                textStyle="colfax-14-medium-uppercased"
                color="grey.600"
                mb={1}
              >
                {t(`unlisted_holding`)}
              </Text>
              <Text textStyle="colfax-16-regular" color="grey.600" mb={3}>
                {holding?.companyName}
              </Text>
              <CompanySearchCombobox
                label={t(`listed_company`)}
                value={companyId}
                setValueCallback={handleCompanySelect}
                error={errors.companyId}
                rejectIds={currentHoldings.map(
                  (holding) => holding.company?.id || ``,
                )}
                {...register(`companyId`)}
              />
            </>
          }
          isOpen={open}
          onClose={handleClose}
          onConfirm={handleSubmit(() => {
            setStep(Step.Confirmation);
          })}
        />
      );
    case Step.Confirmation:
      return (
        <ConfirmModal
          title={t(`convert_holding_confirmation_title`)}
          confirmText={t(`confirm`)}
          body={
            <Text textStyle="colfax-16-regular" color="grey.600">
              <Trans
                i18nKey="convert_holding_confirmation_body"
                values={{
                  unlistedCompanyName: holding.companyName,
                  listedCompanyName: getValues(`companyName`),
                }}
                components={{ bold: <strong /> }}
              />
            </Text>
          }
          isOpen={open}
          onClose={handleClose}
          onConfirm={async () => {
            const { data } = await convertUnlistedHolding(
              {
                companyId,
                sellerId: user.id,
                unlistedHoldingId: holding.id,
              },
              { additionalTypenames: [`UnlistedHolding`] },
            );

            if (data?.convertUnlistedHolding?.holding?.id) {
              successToast();
            } else {
              errorToast();
            }
            handleClose();
          }}
        />
      );
    default:
      return null;
  }
}

import {
  investorStatusTranslations,
  investorTypeTranslations,
  roleTranslations,
} from "@/features/Users";
import { UserDetailFragment, UserPageQuery } from "@/graphql";

export type MappedUser = UserDetailFragment & {
  membershipAgreementUrl: string | null | undefined;
  translatedRoles: string[];
  translatedInvestorType: string | null | undefined;
  translatedInvestorStatus: string | null | undefined;
};

export const mapUserDetails = (data?: UserPageQuery) => {
  if (!data?.user) return null;

  const { roles, investorType, investorStatus } = data.user;

  const translatedRoles = roles.map((role) => roleTranslations[role]());
  const translatedInvestorType =
    investorType && investorTypeTranslations[investorType]();
  const translatedInvestorStatus =
    investorStatus && investorStatusTranslations[investorStatus]();

  const membershipAgreementUrl = data.currentMembershipAgreementByUser;

  return {
    ...data.user,
    membershipAgreementUrl,
    translatedRoles,
    translatedInvestorType,
    translatedInvestorStatus,
  };
};

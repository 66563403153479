import { DetailPageLayout } from "@/modules/Layout";

import UserMainPanelSkeleton from "./UserMainPanelSkeleton";
import UserRightPanelSkeleton from "./UserRightPanelSkeleton";
import UserPageNavMenuSkeleton from "./UserPageNavMenuSkeleton";

export default function UserPageSkeleton() {
  return (
    <DetailPageLayout
      isTwoColumn
      mainPanel={<UserMainPanelSkeleton />}
      tabMenu={<UserPageNavMenuSkeleton />}
      rightPanel={<UserRightPanelSkeleton />}
    />
  );
}

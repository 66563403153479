import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";

import {
  useRemoveFromUserWatchlistMutation,
  useUserWatchlistQuery,
} from "@/graphql";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import UserWatchlistSkeleton from "./UserWatchlistSkeleton";
import UsersCompanyWatchlistCombobox from "./UsersCompanyWatchlistCombobox";

interface UserWatchlistProps {
  userId: string;
}

export default function UserWatchlist({ userId }: UserWatchlistProps) {
  const { t } = useTranslation();

  const [{ data, fetching }, executeQuery] = useUserWatchlistQuery({
    variables: { userId },
    requestPolicy: `cache-and-network`,
  });
  const refetch = useCallback(() => {
    executeQuery({ requestPolicy: `network-only` });
  }, [executeQuery]);

  const [_removeFromUserWatchlistResult, removeFromUserWatchlist] =
    useRemoveFromUserWatchlistMutation();

  if (fetching) return <UserWatchlistSkeleton />;
  if (!data) return null;

  const companiesOnWatchlist = data.watchlistByUser.map(
    ({ company }) => company,
  );

  const handleRemove = (companyId: string) => {
    removeFromUserWatchlist({ userId, companyId });
  };

  return (
    <Card w="full">
      <Box w={480}>
        <CardHeader>
          <Text textStyle="colfax-22-medium">{t(`watchlist`)}</Text>
        </CardHeader>
        <CardBody>
          <VStack alignItems="flex-start" gap={4}>
            <UsersCompanyWatchlistCombobox
              companiesOnWatchlist={companiesOnWatchlist}
              setValueCallback={refetch}
              userId={userId}
            />

            {companiesOnWatchlist.length === 0 && (
              <Text>{t(`no_companies_in_the_watchlist`)}</Text>
            )}

            {orderBy(companiesOnWatchlist, `name`).map((company) => (
              <HStack key={company.id} justifyContent="space-between" w="full">
                <HStack>
                  <Image
                    src={company.logoUrl || `https://placehold.it/24x24`}
                    w="auto"
                    h={6}
                  />
                  <Text textStyle="colfax-16-medium">{company.name}</Text>
                </HStack>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleRemove(company.id)}
                >
                  {t(`remove`)}
                </Button>
              </HStack>
            ))}
          </VStack>
        </CardBody>
      </Box>
    </Card>
  );
}

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserDetailsContext } from "@/features/Users";
import { Card, CardBody, CardHeader, Text, Textarea } from "@chakra-ui/react";

export default function UserDeactivationReasonCard() {
  const { user } = useContext(UserDetailsContext);
  const { t } = useTranslation(`users`);

  if (!user || user.isActive || !user.deactivationReason) return null;

  const { deactivationReason } = user;

  return (
    <Card w="full">
      <CardHeader textStyle="colfax-22-medium">
        {t(`deactivation_reason`)}
      </CardHeader>
      <CardBody>
        <Textarea
          value={deactivationReason}
          disabled
          _disabled={{ color: `grey.900` }}
        />
        <Text textStyle="colfax-14-regular" color="grey.600" mt={2}>
          {t(`confirm_deactivate_textarea_helper_text`)}
        </Text>
      </CardBody>
    </Card>
  );
}

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import {
  InvestorType,
  UserAccreditationSuitabilityQuery,
  InstitutionAccreditationSuitabilityQuery,
} from "@/graphql";
import { Markdown } from "@/modules/Markdown";
import { Status } from "@/modules/Status";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import styles from "./suitability-markdown-style.module.css";

interface SuitabilityAnswersProps {
  answers:
    | UserAccreditationSuitabilityQuery["investmentGoalAnswersByUser"]
    | InstitutionAccreditationSuitabilityQuery["investmentGoalAnswersByInstitution"];
}

function SuitabilityAnswers({ answers }: SuitabilityAnswersProps) {
  const { t } = useTranslation();

  if (!answers || answers.length === 0) {
    return <Text>{t(`no_suitability_answers`)}</Text>;
  }

  return (
    <VStack alignItems="flex-start" gap={12}>
      {answers
        .slice()
        .sort((a, b) => a.option.question.order - b.option.question.order)
        .map((answer) => (
          <VStack key={answer.id} alignItems="flex-start">
            <div className={styles[`suitability-question-text-markdown`]}>
              <Markdown markdown={answer.option.question.text} />
            </div>
            {answer.option.question.description && (
              <div className={styles[`suitability-markdown`]}>
                <Markdown markdown={answer.option.question.description} />
              </div>
            )}
            <HStack>
              <Text>{t(`answer`)}:</Text>
              <Text fontWeight="medium">
                {answer.text || answer.option.text}
              </Text>
            </HStack>
          </VStack>
        ))}
    </VStack>
  );
}

interface SuitabilityProps {
  suitabilityLastUpdatedAt: string;
  investmentGoalAnswers:
    | UserAccreditationSuitabilityQuery["investmentGoalAnswersByUser"]
    | InstitutionAccreditationSuitabilityQuery["investmentGoalAnswersByInstitution"];
  isSuitable: boolean | null | undefined;
  investorType?: InvestorType | null | undefined;
}

export default function Suitability({
  suitabilityLastUpdatedAt,
  investmentGoalAnswers,
  isSuitable,
  investorType,
}: SuitabilityProps) {
  const { t } = useTranslation();

  return (
    <AccordionItem>
      <AccordionButton>
        <Text textStyle="colfax-22-medium">{t(`suitability`)}</Text>
        <HStack>
          <Status.Suitability
            isSuitable={isSuitable}
            investorType={investorType}
          />
          {suitabilityLastUpdatedAt && (
            <Text>
              {DateTime.fromISO(suitabilityLastUpdatedAt).toLocaleString(
                DateTime.DATETIME_FULL,
              )}
            </Text>
          )}
          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <AccordionPanel>
        <SuitabilityAnswers answers={investmentGoalAnswers} />
      </AccordionPanel>
    </AccordionItem>
  );
}

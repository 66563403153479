import { useTranslation } from "react-i18next";

import { CardTable } from "@/modules/CardTable";
import { Skeleton } from "@/modules/Skeleton";
import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Menu,
  MenuButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DotsThreeOutlineVertical } from "@phosphor-icons/react";

export default function UserDetailCardSkeleton() {
  const { t } = useTranslation();

  return (
    <Card w="full">
      <CardHeader as={HStack} gap={4} alignItems="center">
        <Skeleton h={5} w={40} />
        <Box flexGrow={1} />
        <Skeleton h={5} w={20} />
        <Menu>
          <MenuButton>
            <HStack p={1}>
              <DotsThreeOutlineVertical weight="fill" size={16} />
              <Text textStyle="colfax-12-medium">{t(`actions`)}</Text>
            </HStack>
          </MenuButton>
        </Menu>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" gap={8}>
          <VStack alignItems="flex-start" w="full">
            <HStack>
              <Text textStyle="colfax-14-medium-uncased">{t(`email`)}:</Text>
              <Skeleton h={5} w={60} />
            </HStack>
            <HStack>
              <Text textStyle="colfax-14-medium-uncased">{t(`user_id`)}:</Text>
              <Skeleton h={5} w={60} />
            </HStack>
            <HStack>
              <Text textStyle="colfax-14-medium-uncased">{t(`phone`)}:</Text>
              <Skeleton h={5} w={60} />
            </HStack>
          </VStack>

          <CardTable w="full" columns={2}>
            <Card variant="table">
              <CardHeader>{t(`user_info`)}</CardHeader>
              <CardBody>
                <VStack alignItems="flex-start">
                  <Box>
                    <Text textStyle="colfax-14-medium" color="grey.600">
                      {t(`country`)}
                    </Text>
                    <Skeleton h={5} w={20} />
                  </Box>
                  <Box>
                    <Text textStyle="colfax-14-medium" color="grey.600">
                      {t(`investor_status`)}
                    </Text>
                    <Skeleton h={5} w={20} />
                  </Box>
                  <Box>
                    <Text textStyle="colfax-14-medium" color="grey.600">
                      {t(`roles`)}
                    </Text>
                    <Skeleton h={5} w={20} />
                  </Box>
                  <Box>
                    <Text textStyle="colfax-14-medium" color="grey.600">
                      {t(`investor_type`)}
                    </Text>
                    <Skeleton h={5} w={20} />
                  </Box>
                  <Box>
                    <Text textStyle="colfax-14-medium" color="grey.600">
                      {t(`investor_type`)}
                    </Text>
                    <Skeleton h={5} w={20} />
                  </Box>
                </VStack>
              </CardBody>
            </Card>
            <Card variant="table">
              <CardHeader>{t(`status`)}</CardHeader>
              <CardBody>
                <VStack alignItems="flex-start">
                  <Badge variant="italic" gap={3}>
                    <Skeleton h={4} w={5} />
                    <Text>{t(`onboarding_complete`)}</Text>
                  </Badge>
                  <Badge variant="italic" gap={3}>
                    <Skeleton h={4} w={5} />
                    <Text>{t(`customer_agreement`)}</Text>
                  </Badge>
                  <Badge variant="italic" gap={3}>
                    <Skeleton h={4} w={5} />
                    <Text>{t(`identity_verification`)}</Text>
                  </Badge>
                  <Badge variant="italic" gap={3}>
                    <Skeleton h={4} w={5} />
                    <Text>{t(`accreditation`)}</Text>
                  </Badge>
                  <Badge variant="italic" gap={3}>
                    <Skeleton h={4} w={5} />
                    <Text>{t(`suitability`)}</Text>
                  </Badge>
                </VStack>
              </CardBody>
            </Card>
          </CardTable>
        </VStack>
      </CardBody>
    </Card>
  );
}
